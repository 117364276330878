const SortDirection = {
  ASC: 1,
  DESC: -1,
};

const MOIS = [
  { libelleCourt: "JAN", libelleLong: "janvier", },
  { libelleCourt: "FEV", libelleLong: "février", },
  { libelleCourt: "MAR", libelleLong: "mars", },
  { libelleCourt: "AVR", libelleLong: "avril", },
  { libelleCourt: "MAI", libelleLong: "mai", },
  { libelleCourt: "JUIN", libelleLong: "juin", },
  { libelleCourt: "JUIL", libelleLong: "juillet", },
  { libelleCourt: "AOUT", libelleLong: "août", },
  { libelleCourt: "SEPT", libelleLong: "septembre", },
  { libelleCourt: "OCT", libelleLong: "octobre", },
  { libelleCourt: "NOV", libelleLong: "novembre", },
  { libelleCourt: "DEC", libelleLong: "décembre", },
];

class UtilsService {

  // GESTION DES ERREURS

  /**
   * Renvoie un objet { status, error, message } correspondant à l'erreur passée en paramètre <br />
   * Prend en charge : <br />
   * - les erreurs applicatives en réponse à une requête réseau <br />
   * - les erreurs d'envoi de requête réseau <br />
   * - les erreurs Javascript locales
   * 
   * @param {*} error 
   * @returns 
   */
  handleError = function (error) {
    console.error(error);
    if (error.response) {
      // Erreur renvoyee par l'application
      return error.response.data;
    } else if (error.request) {
      // Aucun reponse recue => probleme de connectivite ?
      return {
        status: "",
        error: "",
        message:
          "Oups... Vous n'avez actuellement pas de connexion à Internet.",
      };
    } else {
      // Erreur avant envoi de la requete
      return { status: "", error: error?.name, message: error?.message };
    }
  }

  // ENUMERATIONS JAVA-LIKE

  /**
   * Génère une énumération dans le style Java avec : 
   * - Une propriété par nom de valeur spécifiée au format { name: string, ordinal: number, ... }
   * - Une propriété "values" permettant d'accéder à une valeur par rang
   * - Une fonction "valueOf" permettant d'accéder à une valeur par nom
   * 
   * @param {*} enumValues valeurs au format [{ name: string, ... }, ...]
   * @returns 
   */
  generateEnumClass = function (enumValues) {
    // Initialiser la "classe" de l'enum
    let enumClass = {};

    // Pour chaque valeur fournie
    enumValues.forEach((e, i) => {
      // Y ajouter son rang (propriété ordinal)
      e.ordinal = i;

      // L'affecter à la classe sous le nom fourni
      enumClass[e.name] = e;
    });

    // Permet d'accéder à une valeur à partir de son rang
    enumClass.values = enumValues;

    // Permet d'accéder à une valeur à partir de son nom
    enumClass.valueOf = function (name) {
      return enumValues.find((e) => e.name === name);
    };

    // Gèle la classe et tout ce qu'elle référence
    Object.freeze(enumClass);

    return enumClass;
  }

  // GESTION DES EVENEMENTS

  debounce = function (context, callback, milliseconds) {
    let timer;
    return function (...args) {
      // Annuler le timer en cours
      if (timer) {
        clearTimeout(timer);
      }
      // Lancer le nouveau timer
      timer = setTimeout(() => {
        callback.apply(context, args);
      }, milliseconds);
    };
  };

  // FONCTIONS DE FILTRAGE / TRIAGE

  /**
   * Retourne la valeur de la propriété pointée par le path spécifié <br />
   * Utilise la notation pointée par défaut, mais plusieurs usages sont possibles <br />
   * Cf. https://stackoverflow.com/questions/6491463/accessing-nested-javascript-objects-and-arrays-by-string-path/22129960#22129960
   * 
   * @param {*} propertyPath 
   * @param {*} object 
   * @param {*} separator 
   * @returns 
   */
  getNestedProperty = function (propertyPath, object = self, separator = '.') {
    var properties = Array.isArray(propertyPath) ? propertyPath : propertyPath.split(separator);
    return properties.reduce((prev, curr) => prev?.[curr], object);
  }
  /**
   * Retourne une fonction de filtrage complexe correspondant au filtre fourni en paramètre <br />
   * Cette fonction est récursive, le niveau d'imbrication du filtre en paramètre n'est pas limité <br />
   * Les filtrages sont des fonctions (value) => Boolean au sens de Array.filter()
   * 
   * @param  {*} filter 
   * @returns 
   */
  filterBy(filter) {
    // Si pas de filtre, on ne filtre rien
    if (!filter) {
      return () => true;
    }

    // On construit le filtre en fonction des opérations demandées
    let service = this;
    if (filter.operation === "$and") {
      let predicates = filter.operands.map((f) => service.filterBy(f));
      return function (value) {
        return predicates.reduce((acc, next) => acc && next(value), true);
      };
    }
    if (filter.operation === "$or") {
      let predicates = filter.operands.map((f) => service.filterBy(f));
      return function (value) {
        return predicates.reduce((acc, next) => acc && next(value), true);
      };
    }
    if (filter.operation === "$not") {
      return function (value) {
        return !service.filterBy(filter.operands[0])(value);
      };
    }
    if (filter.operation === "$null") {
      return function (value) {
        let property = service.getNestedProperty(filter.property, value);
        return property == null;
      };
    }
    if (filter.operation === "$notNull") {
      return function (value) {
        let property = service.getNestedProperty(filter.property, value);
        return property != null;
      };
    }
    if (filter.operation === "$eq") {
      return function (value) {
        let property = service.getNestedProperty(filter.property, value);
        property = filter.ignoreCase === true ? String(property).toLocaleLowerCase() : property;
        let operand = filter.ignoreCase === true ? String(filter.operands[0]).toLocaleLowerCase() : filter.operands[0];
        return property == operand;
      };
    }
    if (filter.operation === "$ne") {
      return function (value) {
        let property = service.getNestedProperty(filter.property, value);
        property = filter.ignoreCase === true ? String(property).toLocaleLowerCase() : property;
        let operand = filter.ignoreCase === true ? String(filter.operands[0]).toLocaleLowerCase() : filter.operands[0];
        return property != operand;
      };
    }
    if (filter.operation === "$lt") {
      return function (value) {
        let property = service.getNestedProperty(filter.property, value);
        property = filter.ignoreCase === true ? String(property).toLocaleLowerCase() : property;
        let operand = filter.ignoreCase === true ? String(filter.operands[0]).toLocaleLowerCase() : filter.operands[0];
        return property < operand;
      };
    }
    if (filter.operation === "$lte") {
      return function (value) {
        let property = service.getNestedProperty(filter.property, value);
        property = filter.ignoreCase === true ? String(property).toLocaleLowerCase() : property;
        let operand = filter.ignoreCase === true ? String(filter.operands[0]).toLocaleLowerCase() : filter.operands[0];
        return property <= operand;
      };
    }
    if (filter.operation === "$gt") {
      return function (value) {
        let property = service.getNestedProperty(filter.property, value);
        property = filter.ignoreCase === true ? String(property).toLocaleLowerCase() : property;
        let operand = filter.ignoreCase === true ? String(filter.operands[0]).toLocaleLowerCase() : filter.operands[0];
        return property > operand;
      };
    }
    if (filter.operation === "$gte") {
      return function (value) {
        let property = service.getNestedProperty(filter.property, value);
        property = filter.ignoreCase === true ? String(property).toLocaleLowerCase() : property;
        let operand = filter.ignoreCase === true ? String(filter.operands[0]).toLocaleLowerCase() : filter.operands[0];
        return property >= operand;
      };
    }
    if (filter.operation === "$between") {
      return function (value) {
        let property = service.getNestedProperty(filter.property, value);
        property = filter.ignoreCase === true ? String(property).toLocaleLowerCase() : property;
        let operand0 = filter.ignoreCase === true ? String(filter.operands[0]).toLocaleLowerCase() : filter.operands[0];
        let operand1 = filter.ignoreCase === true ? String(filter.operands[1]).toLocaleLowerCase() : filter.operands[1];
        return property >= operand0 && property <= operand1;
      };
    }
    if (filter.operation === "$notBetween") {
      return function (value) {
        let property = service.getNestedProperty(filter.property, value);
        property = filter.ignoreCase === true ? String(property).toLocaleLowerCase() : property;
        let operand0 = filter.ignoreCase === true ? String(filter.operands[0]).toLocaleLowerCase() : filter.operands[0];
        let operand1 = filter.ignoreCase === true ? String(filter.operands[1]).toLocaleLowerCase() : filter.operands[1];
        return property < operand0 || property > operand1;
      };
    }
    if (filter.operation === "$in") {
      return function (value) {
        let property = service.getNestedProperty(filter.property, value);
        property = filter.ignoreCase === true ? String(property).toLocaleLowerCase() : property;
        return !!filter.operands.map(o => filter.ignoreCase === true ? String(o).toLocaleLowerCase : o).find(o => o == property);
      };
    }
    if (filter.operation === "$notIn") {
      return function (value) {
        let property = service.getNestedProperty(filter.property, value);
        property = filter.ignoreCase === true ? String(property).toLocaleLowerCase() : property;
        return !filter.operands.map(o => filter.ignoreCase === true ? String(o).toLocaleLowerCase : o).find(o => o == property);
      };
    }
    if (filter.operation === "$startsWith") {
      return function (value) {
        let property = service.getNestedProperty(filter.property, value);
        property = filter.ignoreCase === true ? String(property).toLocaleLowerCase() : String(property);
        let operand = filter.ignoreCase === true ? String(filter.operands[0]).toLocaleLowerCase() : String(filter.operands[0]);
        return property != null && property.startsWith(operand);
      };
    }
    if (filter.operation === "$notStartsWith") {
      return function (value) {
        let property = service.getNestedProperty(filter.property, value);
        property = filter.ignoreCase === true ? String(property).toLocaleLowerCase() : String(property);
        let operand = filter.ignoreCase === true ? String(filter.operands[0]).toLocaleLowerCase() : String(filter.operands[0]);
        return property == null || !property.startsWith(operand);
      };
    }
    if (filter.operation === "$contains") {
      return function (value) {
        let property = service.getNestedProperty(filter.property, value);
        property = filter.ignoreCase === true ? String(property).toLocaleLowerCase() : String(property);
        let operand = filter.ignoreCase === true ? String(filter.operands[0]).toLocaleLowerCase() : String(filter.operands[0]);
        return property != null && property.includes(operand);
      };
    }
    if (filter.operation === "$notContains") {
      return function (value) {
        let property = service.getNestedProperty(filter.property, value);
        property = filter.ignoreCase === true ? String(property).toLocaleLowerCase() : String(property);
        let operand = filter.ignoreCase === true ? String(filter.operands[0]).toLocaleLowerCase() : String(filter.operands[0]);
        return property == null || !property.includes(operand);
      };
    }
    if (filter.operation === "$endsWith") {
      return function (value) {
        let property = service.getNestedProperty(filter.property, value);
        property = filter.ignoreCase === true ? String(property).toLocaleLowerCase() : String(property);
        let operand = filter.ignoreCase === true ? String(filter.operands[0]).toLocaleLowerCase() : String(filter.operands[0]);
        return property != null && property.endsWith(operand);
      };
    }
    if (filter.operation === "$notEndsWith") {
      return function (value) {
        let property = service.getNestedProperty(filter.property, value);
        property = filter.ignoreCase === true ? String(property).toLocaleLowerCase() : String(property);
        let operand = filter.ignoreCase === true ? String(filter.operands[0]).toLocaleLowerCase() : String(filter.operands[0]);
        return property == null || !property.endsWith(operand);
      };
    }

    // Failsafe en cas d'usage incorrect
    throw new Error(`L'opération ${filter.operation} n'est pas supportée`);
  }
  /**
   * Retourne une fonction de tri simple permettant de comparer deux objets sur la base d'une propriété de type String <br />
   * La valeur par défaut est utilisée si la propriété spécifiée est null ou undefined
   * 
   * @param {*} propertyPath 
   * @param {*} sortDirection 
   * @param {*} defaultValue 
   * @returns 
   */
  sortByStringProperty = function (propertyPath, sortDirection = SortDirection.ASC, defaultValue = "") {
    let service = this;
    return function (e1, e2) {
      let a = service.getNestedProperty(propertyPath, e1) ?? defaultValue;
      let b = service.getNestedProperty(propertyPath, e2) ?? defaultValue;
      return a.localeCompare(b) * sortDirection;
    }
  }
  /**
   * Retourne une fonction de tri simple permettant de comparer deux objets sur la base d'une propriété de type Number <br />
   * La valeur par défaut est utilisée si la propriété spécifiée est null ou undefined
   * 
   * @param {*} propertyPath 
   * @param {*} sortDirection 
   * @param {*} defaultValue 
   * @returns 
   */
  sortByNumberProperty = function (propertyPath, sortDirection = SortDirection.ASC, defaultValue = 0) {
    let service = this;
    return function (e1, e2) {
      let a = service.getNestedProperty(propertyPath, e1) ?? defaultValue;
      let b = service.getNestedProperty(propertyPath, e2) ?? defaultValue;
      return (a - b) * sortDirection;
    }
  }
  /**
   * Retourne une fonction de tri simple permettant de comparer deux objets sur la base d'une propriété de type Boolean <br />
   * Sont considérées true les valeurs true et "true" ; toute autre valeur est considérée false
   * 
   * @param {*} propertyPath 
   * @param {*} sortDirection 
   * @returns 
   */
  sortByBooleanProperty = function (propertyPath, sortDirection = SortDirection.ASC) {
    let service = this;
    return function (e1, e2) {
      let a = service.getNestedProperty(propertyPath, e1);
      a = a === true || a === "true" ? 1 : 0;
      let b = service.getNestedProperty(propertyPath, e2);
      b = b === true || b === "true" ? 1 : 0;
      return (a - b) * sortDirection;
    }
  }
  /**
   * Retourne une fonction de tri complexe permettant de comparer deux objets sur la base d'une propriété de type Array <br />
   * Si le paramètre sort est true, les tableaux sont effectivement triés avant comparaison <br />
   * Dans tous les cas, les objets sont triés en comparant la plus "petite" valeur des deux tableaux
   * 
   * @param {*} propertyPath 
   * @param {*} comparator 
   * @param {*} sort 
   * @returns 
   */
  sortByArrayProperty = function (propertyPath, comparator = (a, b) => (a ?? "").localeCompare(b ?? ""), sort = false) {
    let service = this;
    return function (e1, e2) {
      // Récupération des tableaux à comparer
      let array1 = service.getNestedProperty(propertyPath, e1);
      let array2 = service.getNestedProperty(propertyPath, e2);

      let min1, min2;
      if (sort) {
        // Tri effectif des tableaux
        array1?.sort(comparator);
        array2?.sort(comparator);
        min1 = array1?.[0];
        min2 = array2?.[0];
      } else {
        // Récupération des minimums sans tri
        min1 = service.min(array1, comparator);
        min2 = service.min(array2, comparator);
      }

      // Interception des tableaux non définis ou vides
      if ((array1?.length || 0) === 0 || (array2?.length || 0) === 0) {
        return (array1?.length || 0) - (array2?.length || 0);
      }

      // Comparaison des plus "petites" valeurs de chaque tableau
      return comparator(min1, min2);
    }
  }
  /**
   * Retourne une fonction de tri complexe combinant les tris fournis en paramètre dans l'ordre <br />
   * Les tris sont des fonctions de comparaison de type (e1, e2) => -1 / 0 / 1
   * 
   * @param  {...any} comparators 
   * @returns 
   */
  sortBy(...comparators) {
    return function (e1, e2) {
      let ordre = 0;
      for (let comparator of comparators) {
        ordre = comparator(e1, e2);
        if (ordre !== 0) {
          return ordre;
        }
      }
      return ordre;
    }
  }

  // Fonctions de manipulation de tableaux

  /**
   * Génère une range (tableau d'entiers) allant de base à base + size - 1 
   * 
   * @param {*} size 
   * @param {*} base 
   * @returns 
   */
  range(size, base = 0) {
    return Array.from(new Array(size), (_, i) => base + i);
  }
  /**
   * Renvoie la plus "petite" valeur du tableau au sens du comparateur spécifié. <br />
   * Le tri par défaut est l'ordre alphanumérique (localeCompare).
   * 
   * @param {*} lookupArray 
   * @param {*} comparator 
   * @returns 
   */
  min(lookupArray, comparator = (a, b) => (a ?? "").localeCompare(b ?? "")) {
    return lookupArray?.reduce((acc, next) =>
      comparator(acc, next) < 0 ? acc : next
    );
  }
  /**
   * Renvoie la plus "grande" valeur du tableau au sens du comparateur spécifié. <br />
   * Le tri par défaut est l'ordre alphanumérique (localeCompare).
   * 
   * @param {*} lookupArray 
   * @param {*} comparator 
   * @returns 
   */
  max(lookupArray, comparator = (a, b) => (a ?? "").localeCompare(b ?? "")) {
    return lookupArray?.reduce((acc, next) =>
      comparator(acc, next) > 0 ? acc : next
    );
  }
  /**
   * Echange l'élément à la place index avec l'élément précédent
   * 
   * @param {*} array 
   * @param {*} index 
   * @returns 
   */
  moveUp(array, index) {
    if (index == null || index <= 0) {
      return;
    }
    array.splice(index - 1, 2, array[index], array[index - 1]);
  }
  /**
   * Echange l'élément à la place index avec l'élément suivant
   * 
   * @param {*} array 
   * @param {*} index 
   * @returns 
   */
  moveDown(array, index) {
    if (index == null || index >= array.length - 1) {
      return;
    }
    array.splice(index, 2, array[index + 1], array[index]);
  }

  // ACCESSEURS DE PAGINATION

  /**
   * Renvoie une page dont le contenu est référencé sans copie <br />
   * Une modification sur l'un des objets de la page impacte le contenu du tableau source
   * 
   * @param {*} storeArray 
   * @param {*} numPage 
   * @param {*} pageSize 
   * @param {*} sortFunction 
   * @returns 
   */
  readOnlyPage = function (storeArray = [], numPage = 0, pageSize = 10, sortFunction = () => 0) {
    let copiedArray = storeArray.slice().sort(sortFunction);
    let totalPages = copiedArray.length === 0 ? 1 : Math.ceil(copiedArray.length / pageSize);
    // Les pages sont 0-based dans l'objet Page
    numPage = Math.min(Math.max(numPage, 0), totalPages - 1);
    pageSize = Math.max(1, pageSize);
    return {
      content: copiedArray.slice(numPage * pageSize, (numPage + 1) * pageSize),
      number: numPage,
      size: pageSize,
      totalPages: totalPages,
      totalElements: storeArray.length,
    }
  }
  /**
   * Renvoie une page dont le contenu est copié (deep copy via JSON) <br />
   * Une modification sur l'un des objets de la page n'impacte pas le contenu du tableau source
   * 
   * @param {*} storeArray 
   * @param {*} numPage 
   * @param {*} pageSize 
   * @param {*} sortFunction 
   * @returns 
   */
  readWritePage = function (storeArray = [], numPage = 0, pageSize = 10, sortFunction = () => 0) {
    let page = this.readOnlyPage(storeArray, numPage, pageSize, sortFunction);
    page.content = this.deepCopy(page.content);
    return page;
  }
  /**
   * Renvoie une deep copy de l'objet en parametre (deep copy via JSON)
   * 
   * @param {*} object 
   * @returns 
   */
  deepCopy = function (object) {
    return object ? JSON.parse(JSON.stringify(object)) : null;
  }

  // GESTION DES DATES

  /**
   * Applique un décalage à la date fournie et renvoie cette même date modifiée.
   * Par défaut, l'unité est "Date" soit un décalage en nombre de jours.
   * Peut également être utilisé avec "Month" ou "FullYear".
   * 
   * @param {*} date 
   * @param {*} amount 
   * @param {*} unit 
   * @returns 
   */
  dateApplyOffset(date, amount, unit = "Date") {
    date[`set${unit}`](date[`get${unit}`]() + amount);
    return date;
  }

  // Conversions Date => String

  /**
   * Si data est plus petit que placeholder, renvoie data complété à gauche par placeholder.
   * Si data est plus grand que placeholder, TRONQUE data à la taille de placeholder.
   * 
   * @param {*} entier 
   * @param {*} length 
   * @returns 
   */
  leftPad(data, placeholder = "00") {
    return `${placeholder}${data}`.slice(-placeholder.length);
  }
  /**
   * Renvoie la date fournie au format string ISO/SQL (yyyy-MM-dd)
   * 
   * @param {*} date 
   */
  dateToIsoSqlDate(date) {
    let year = this.leftPad(date.getFullYear(), "0000");
    let month = this.leftPad(date.getMonth() + 1);
    let day = this.leftPad(date.getDate());
    return `${year}-${month}-${day}`;
  }
  /**
   * Renvoie la date fournie au format string français (dd/MM/yyyy)
   * 
   * @param {*} date 
   */
  dateToFrenchDate(date) {
    let day = this.leftPad(date.getDate());
    let month = this.leftPad(date.getMonth() + 1);
    let year = this.leftPad(date.getFullYear(), "0000");
    return `${day}/${month}/${year}`;
  }
  /**
   * Renvoie la date/heure fournie au format string français (dd/MM/yyyy à HH:mm)
   * 
   * @param {*} date 
   */
  dateToFrenchInstant(date) {
    let day = this.leftPad(date.getDate());
    let month = this.leftPad(date.getMonth() + 1);
    let year = this.leftPad(date.getFullYear(), "0000");
    let hour = this.leftPad(date.getHours());
    let minute = this.leftPad(date.getMinutes());
    return `${day}/${month}/${year} à ${hour}:${minute}`;
  }
  /**
   * Renvoie la date fournie au format string ICS (yyyyMMddTHHmmssZ)
   * 
   * @param {*} date 
   */
  dateToIcsDate(date) {
    const year = this.leftPad(date.getUTCFullYear(), "0000");
    const month = this.leftPad(date.getUTCMonth() + 1);
    const day = this.leftPad(date.getUTCDate());
    const hour = this.leftPad(date.getUTCHours());
    const minute = this.leftPad(date.getUTCMinutes());
    const second = this.leftPad(date.getUTCSeconds());
    return `${year}${month}${day}T${hour}${minute}${second}Z`;
  }

  // Conversions String => String

  /**
   * Convertit une date string format ISO/SQL (yyyy-MM-dd)
   * en une date string au format français (dd/MM/yyyy ou dd/MM/yy)
   * @param {*} date 
   * @param {*} fullYear 
   */
  isoSqlDateToFrenchDate(date, fullYear = true) {
    let parts = date?.split("-").reverse() ?? [];
    if (fullYear !== true) {
      parts = parts.map(p => p.slice(-2));
    }
    return parts.join("/");
  }
  /**
   * Convertit une date string format français (dd/MM/yyyy)
   * en une date string au format ISO/SQL (yyyy-MM-dd)
   * @param {*} date 
   */
  frenchDateToIsoSqlDate(date) {
    return date?.split("/").reverse().join("-") || "";
  }
  /**
   * Convertit un instant string format ISO/SQL (yyyy-MM-ddTHH:mm:ss.nnn[nnn]Z)
   * en un instant string au format français (dd/MM/yy[yy] à HHhmm)
   * @param {*} instant 
   * @param {*} fullYear 
   */
  isoSqlInstantToFrenchInstant(instant) {
    return this.dateToFrenchInstant(new Date(instant));
  }

  // Autres accesseurs pour les dates

  /**
   * Renvoie le libellé court du mois correspondant
   * ou "" si le paramètre est invalide
   * @param {*} numeroMois numéro du mois entre 1 et 12
   */
  getMoisCourt(numeroMois) {
    if (typeof numeroMois === 'string') {
      numeroMois = parseInt(numeroMois);
    }
    return MOIS[numeroMois - 1]?.libelleCourt ?? "";
  }
  /**
   * Renvoie le libellé long du mois correspondant
   * ou "" si le paramètre est invalide
   * @param {*} numeroMois numéro du mois entre 1 et 12
   */
  getMoisLong(numeroMois) {
    if (typeof numeroMois === 'string') {
      numeroMois = parseInt(numeroMois);
    }
    return MOIS[numeroMois - 1]?.libelleLong ?? "";
  }

  // GESTION DES ACCENTS

  /**
   * Remplace tous les caractères accentués latins par leur équivalent non accentué
   * Cf. https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
   * Cf. https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/normalize
   * @param {*} text 
   */
  replaceAccents(text) {
    return text.normalize("NFD").replace(/\p{Diacritic}/gu, "");
  }

}

export default new UtilsService();
export { SortDirection };
