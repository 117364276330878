<template>
  <svg
    class="font-icon"
    width="20"
    height="18"
    viewBox="0 0 20 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.77291 0.458361C9.11778 0.16259 9.55712 0 10.0115 0C10.4658 0 10.9051 0.16259 11.25 0.458361L17.2772 5.62372L19.643 7.51674C19.7406 7.59482 19.8218 7.69134 19.8821 7.80081C19.9423 7.91028 19.9804 8.03055 19.9942 8.15474C20.008 8.27894 19.9972 8.40463 19.9624 8.52465C19.9276 8.64466 19.8695 8.75665 19.7914 8.85422C19.7133 8.95178 19.6168 9.03301 19.5073 9.09327C19.3979 9.15353 19.2776 9.19164 19.1534 9.20541C19.0292 9.21919 18.9035 9.20838 18.7835 9.17358C18.6635 9.13878 18.5515 9.08069 18.4539 9.00261L17.6216 8.33673V15.8698C17.6216 16.3744 17.4211 16.8583 17.0643 17.2151C16.7075 17.5719 16.2236 17.7723 15.719 17.7723H11.914V13.016C11.914 12.5114 11.7135 12.0275 11.3567 11.6707C10.9999 11.3139 10.516 11.1135 10.0115 11.1135C9.50687 11.1135 9.02295 11.3139 8.66616 11.6707C8.30937 12.0275 8.10892 12.5114 8.10892 13.016V17.7723H4.30387C3.79929 17.7723 3.31537 17.5719 2.95858 17.2151C2.60178 16.8583 2.40134 16.3744 2.40134 15.8698V8.33578L1.56898 9.00166C1.47208 9.08441 1.35955 9.14684 1.23805 9.18525C1.11656 9.22367 0.988586 9.23728 0.86173 9.22529C0.734873 9.2133 0.61172 9.17594 0.499578 9.11544C0.387437 9.05493 0.288594 8.97252 0.208916 8.87308C0.129239 8.77364 0.0703505 8.65921 0.0357476 8.53658C0.00114464 8.41394 -0.00846788 8.28561 0.00748049 8.15919C0.0234289 8.03277 0.064613 7.91084 0.128589 7.80064C0.192564 7.69045 0.278027 7.59422 0.379904 7.51769L2.7457 5.62467L8.77291 0.458361Z"
    />
  </svg>
</template>

<script>
export default {
  name: "AccueilMenuIcon",
};
</script>
