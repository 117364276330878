import DeclarationsService from "../services/declarations.service";
import UtilsService from "../services/utils.service";

/**
 * Renvoie dans un nouveau tableau les objets du 1er paramètre mis à jour avec ceux du 2e paramètre. <br />
 * Le rapprochement se fait sur le champ 'id' de chaque objet ; un objet non présent dans le 1er paramètre y est automatiquement ajouté.
 * 
 * @param {*} stateCollection 
 * @param {*} addReplaceCollection 
 * @returns 
 */
const addReplace = (stateCollection, addReplaceCollection) => {
  return [
    // Modifications
    ...stateCollection.map((e) => {
      let updated = addReplaceCollection.find((r) => r.id === e.id);
      return updated ? updated : e;
    }),
    // Ajouts
    ...addReplaceCollection.filter((a) => {
      let existing = stateCollection.find((e) => e.id === a.id);
      return existing ? false : true;
    })
  ];
};

/**
 * Renvoie dans un nouveau tableau les objets du 1er paramètre sauf ceux dont l'id est présent dans le 2e paramètre.
 * 
 * @param {*} stateCollection 
 * @param {*} removedCollection 
 * @returns 
 */
const remove = (stateCollection, removedCollection) => {
  // Suppressions (par id)
  return stateCollection.filter((e) => {
    let removed = removedCollection.find((id) => id === e.id);
    return removed ? false : true;
  });
};

/**
 * Pour chaque champ de l'objet DeclarationsDto renvoyé par l'API, indique : 
 * - la collection du store à mettre à jour
 * - la fonction à utiliser pour effectuer le merge
 * - le commit à utiliser pour enregistrer le résultat dans le store
 */
const mergeMetadata = [
  { field: "ilots", collection: "ilots", mergeFunction: addReplace, commitName: "setIlots", },
  { field: "parcelles", collection: "parcelles", mergeFunction: addReplace, commitName: "setParcelles", },
  { field: "emblavements", collection: "emblavements", mergeFunction: addReplace, commitName: "setEmblavements", },
  { field: "cultures", collection: "cultures", mergeFunction: addReplace, commitName: "setCultures", },
  { field: "moissons", collection: "moissons", mergeFunction: addReplace, commitName: "setMoissons", },
  { field: "autoconso", collection: "autoconso", mergeFunction: addReplace, commitName: "setAutoconso", },
  { field: "enlevements", collection: "enlevements", mergeFunction: addReplace, commitName: "setEnlevements", },
  { field: "deletedIlots", collection: "ilots", mergeFunction: remove, commitName: "setIlots", },
  { field: "deletedParcelles", collection: "parcelles", mergeFunction: remove, commitName: "setParcelles", },
  { field: "deletedEmblavements", collection: "emblavements", mergeFunction: remove, commitName: "setEmblavements", },
  { field: "deletedCultures", collection: "cultures", mergeFunction: remove, commitName: "setCultures", },
  { field: "deletedMoissons", collection: "moissons", mergeFunction: remove, commitName: "setMoissons", },
  { field: "deletedAutoconso", collection: "autoconso", mergeFunction: remove, commitName: "setAutoconso", },
  { field: "deletedEnlevements", collection: "enlevements", mergeFunction: remove, commitName: "setEnlevements", },
];

const anomaliesParcelle = {
  EMBLAVEMENT_NON_SAISI: "EMBLAVEMENT_NON_SAISI",
  PARCELLE_NON_SEMEE: "PARCELLE_NON_SEMEE",
  PARCELLE_NON_MOISSONNEE: "PARCELLE_NON_MOISSONNEE",
};
const anomaliesCulture = {
  AUTOCONSO_NON_SAISIE: "AUTOCONSO_NON_SAISIE",
  ENLEVEMENT_NON_SAISI: "ENLEVEMENT_NON_SAISI",
};
const anomaliesAutoconso = {
  QUANTITE_INVALIDE: "QUANTITE_INVALIDE",
};
const anomaliesEnlevement = {
  MODE_INVALIDE: "MODE_INVALIDE",
  DATE_INVALIDE: "DATE_INVALIDE",
};

const statutsMoisson = {
  A_MOISSONNER: { libelle: "A moissonner", variant: "warning", order: 1, },
  MOISSONNEE: { libelle: "Moissonnée", variant: "success", order: 2, },
  ABANDONNEE: { libelle: "Ne sera pas moissonnée", variant: "danger", order: 3, },
}

export const decl = {
  namespaced: true,
  state: {
    codeRecolte: null,
    ilots: [],
    parcelles: [],
    emblavements: [],
    cultures: [],
    moissons: [],
    autoconso: [],
    enlevements: [],
    anomaliesParcelle,
    anomaliesCulture,
    anomaliesAutoconso,
    anomaliesEnlevement,
    statutsMoisson,
  },
  getters: {
    // Accesseurs standards
    ilot: (state) => (idIlot) =>
      state.ilots.find((i) => i.id === idIlot),
    parcelle: (state) => (idParcelle) =>
      state.parcelles.find((p) => p.id === idParcelle),
    emblavement: (state) => (idEmblavement) =>
      state.emblavements.find((e) => e.id === idEmblavement),
    culture: (state) => (idCulture) =>
      state.cultures.find((c) => c.id === idCulture),
    moisson: (state) => (idResultatMoisson) =>
      state.moissons.find((m) => m.id === idResultatMoisson),
    autoconso: (state, getters) => (idAutoconso) => {
      let autoconso = state.autoconso.find((a) => a.id === idAutoconso);
      if (autoconso?.enTotalite) {
        // Si on autoconsomme tout, calcul de la quantité correspondante à la volée
        if (autoconso.idCulture) {
          autoconso.quantiteAutoconsommee = getters.quantiteTotaleCulture(autoconso.idCulture);
        }
        if (autoconso.idEmblavement) {
          autoconso.quantiteAutoconsommee = getters.quantiteTotaleEmblavement(autoconso.idEmblavement);
        }
      }
      return autoconso;
    },
    enlevement: (state) => (idEnlevement) =>
      state.enlevements.find((e) => e.id === idEnlevement),
    // Accesseurs métier
    emblavementsFromParcelle: (state) => (idParcelle, etatEmblavement) =>
      state.emblavements.filter((e) => e.idParcelle === idParcelle && e.etatEmblavement === (etatEmblavement ?? "COURANT")),
    autoconsoFromCulture: (state, getters) => (idCulture) => {
      let autoconso = state.autoconso.find((a) => a.idCulture === idCulture);
      if (autoconso?.enTotalite) {
        // Si on autoconsomme tout, calcul de la quantité correspondante à la volée
        autoconso.quantiteAutoconsommee = getters.quantiteTotaleCulture(idCulture);
      }
      return autoconso;
    },
    quantiteTotaleCulture: (state, getters) => (idCulture) => {
      let parcelles = state.parcelles.filter(p => p.idCulture === idCulture);
      let emblavements = parcelles.flatMap(p => getters.emblavementsFromParcelle(p.id));
      return emblavements.reduce((acc, next) => acc + getters.quantiteTotaleEmblavement(next.id), 0.0);
    },
    autoconsoFromEmblavement: (state, getters) => (idEmblavement) => {
      let autoconso = state.autoconso.find((a) => a.idEmblavement === idEmblavement);
      if (autoconso?.enTotalite) {
        // Si on autoconsomme tout, calcul de la quantité correspondante à la volée
        autoconso.quantiteAutoconsommee = getters.quantiteTotaleEmblavement(idEmblavement);
      }
      return autoconso;
    },
    quantiteTotaleEmblavement: (state, getters) => (idEmblavement) => {
      let emblavement = getters.emblavement(idEmblavement);
      return emblavement ? emblavement.quantiteMoissonnee ?? emblavement.quantitePrevisionnelle : 0.0;
    },
    quantiteAutoconsoEmblavement: (state, getters) => (idEmblavement) => {
      let emblavement = getters.emblavement(idEmblavement);
      let autoconsoEmblavement = getters.autoconsoFromEmblavement(idEmblavement);

      // Si autoconso à l'emblavement
      if (autoconsoEmblavement) {
        if (autoconsoEmblavement.enTotalite) {
          return emblavement.quantiteMoissonnee ?? emblavement.quantitePrevisionnelle;
        }
        return autoconsoEmblavement.quantiteAutoconsommee;
      }

      let parcelle = getters.parcelle(emblavement.idParcelle);
      let autoconsoCulture = getters.autoconsoFromCulture(parcelle.idCulture);

      // Si pas d'autoconso à la culture
      if (!autoconsoCulture) {
        return 0.0;
      }

      // Si autoconso à 100%
      if (autoconsoCulture.enTotalite) {
        return getters.quantiteTotaleCulture(parcelle.idCulture);
      }

      // Sinon prorata au prévisionnel
      let parcelles = state.parcelles.filter(p => p.idCulture === parcelle.idCulture);
      let emblavements = parcelles.flatMap(p => getters.emblavementsFromParcelle(p.id));
      let previsionnelTotal = emblavements.reduce((acc, next) => acc + next.quantitePrevisionnelle, 0.0);
      return autoconsoCulture.quantiteAutoconsommee * emblavement.quantitePrevisionnelle / previsionnelTotal;
    },
    enlevementFromCulture: (state) => (idCulture) =>
      state.enlevements.find((e) => e.idCulture === idCulture),
    enlevementFromEmblavement: (state) => (idEmblavement) =>
      state.enlevements.find((e) => e.idEmblavement === idEmblavement),
    // Accesseurs de synthèse
    nombreParcelles: (state) => state.parcelles.length,
    surfaceExploitation: (state) =>
      state.parcelles.reduce((acc, next) => acc + next.surfaceEmblavee, 0.0),
    quantitePrevisionnelle: (state, getters) =>
      state.parcelles
        .map(p => getters.emblavementsFromParcelle(p.id)
          .reduce((acc, next) => acc + next.rendementAgriculteur, 0.0) * (p.surfaceEmblavee - p.surfaceRetiree))
        .reduce((acc, next) => acc + next, 0.0),
    nombreMelanges: (state, getters) =>
      state.parcelles.filter(
        (p) =>
          getters.emblavementsFromParcelle(p.id).length > 1
      ).length,
    progressionMoisson: (state, getters) => {
      let progression = {
        enAttente: 0.0,
        moissonne: 0.0,
        abandonne: 0.0,
      };
      state.parcelles.forEach(p => {
        // On ignore les parcelles pas encore semées ou supprimées
        if (p.etatParcelle === "INTENTION" || p.etatParcelle === "SUPPRIMEE") {
          return;
        }

        // On ventile les autres en fonction de l'état de la moisson
        let moisson = getters.moisson(p.idMoisson);
        if (!moisson) {
          progression.enAttente += p.surfaceEmblavee;
        } else if (moisson.quantiteMoissonnee > 0.0) {
          progression.moissonne += p.surfaceEmblavee;
        } else {
          progression.abandonne += p.surfaceEmblavee;
        }
      });
      progression.total = Math.round((progression.enAttente + progression.moissonne + progression.abandonne) * 100) / 100;
      progression.enAttente = Math.round(progression.enAttente * 100) / 100;
      progression.moissonne = Math.round(progression.moissonne * 100) / 100;
      progression.abandonne = Math.round(progression.abandonne * 100) / 100;
      return progression;
    },
    quantiteAutoconsommee: (state, getters) =>
      state.autoconso.reduce(
        // FIXME Faire plus propre que ça pour gérer le 100% autoconso...
        (acc, next) => acc + (getters.autoconso(next.id).quantiteAutoconsommee),
        0.0),
    quantiteACollecter: (state, getters) => {
      let disponible = state.emblavements.map(e =>
        e.quantiteMoissonnee ?? e.quantitePrevisionnelle
      ).reduce((acc, next) => acc + next, 0.0);
      let autoconsomme = getters.quantiteAutoconsommee;
      return disponible - autoconsomme;
    },
    periodeEnlevement: (state) => {
      let dates = state.enlevements
        .map((e) => e.dateLimite)
        .sort();
      return dates.length < 1
        ? null
        : [dates[0], dates[dates.length - 1]].map((date) =>
          UtilsService.isoSqlDateToFrenchDate(date, false)
        );
    },
  },
  actions: {
    async load({ commit, dispatch, rootState }, codeRecolte) {
      try {
        let response = await DeclarationsService.getDeclarations(rootState.expl.adherentCourant ?? rootState.expl.exploitationCourante, codeRecolte);
        commit("setCodeRecolte", codeRecolte);
        commit("setIlots", response.data.ilots);
        commit("setParcelles", response.data.parcelles);
        commit("setEmblavements", response.data.emblavements);
        commit("setCultures", response.data.cultures);
        commit("setMoissons", response.data.moissons);
        commit("setAutoconso", response.data.autoconso);
        commit("setEnlevements", response.data.enlevements);
      } catch (e) {
        dispatch("clear");
        throw e;
      }
    },
    sync({ state, commit }, data) {
      mergeMetadata.forEach(m => {
        // Si le champ correspondant est renseigné dans l'objet renvoyé par l'API
        let apiCollection = data[m.field] || [];
        if (apiCollection.length > 0) {
          // Collection du store à mettre à jour
          let stateCollection = state[m.collection];

          // Résultat du merge des données du store et de l'API
          let newState = m.mergeFunction(stateCollection, apiCollection);

          // Commit du résultat dans le store
          commit(m.commitName, newState);
        }
      });
    },
    clear({ commit }) {
      commit("setCodeRecolte", null);
      commit("setIlots", []);
      commit("setParcelles", []);
      commit("setEmblavements", []);
      commit("setCultures", []);
      commit("setMoissons", []);
      commit("setAutoconso", []);
      commit("setEnlevements", []);
    },
    async importerDossierTelepac({ dispatch }, { codeTiers, codeRecolte, formData, }) {
      let response = await DeclarationsService.importerDossierTelepac(codeTiers, codeRecolte, formData);
      dispatch("sync", response.data);
      return response.data;
    },
    async importerDescriptifParcellesTelepac({ dispatch }, { codeTiers, codeRecolte, formData, }) {
      let response = await DeclarationsService.importerDescriptifParcellesTelepac(codeTiers, codeRecolte, formData);
      dispatch("sync", response.data);
      return response.data;
    },
    async copierParcellaire({ dispatch }, { codeTiers, codeRecolte, }) {
      let response = await DeclarationsService.copierParcellaire(codeTiers, codeRecolte);
      dispatch("sync", response.data);
      return response.data;
    },
    async createUpdateIlot({ dispatch }, ilot) {
      let response = await DeclarationsService.createUpdateIlot(ilot);
      dispatch("sync", response.data);
      return response.data;
    },
    async deleteIlot({ dispatch }, idIlot) {
      let response = await DeclarationsService.deleteIlot(idIlot);
      dispatch("sync", response.data);
      return response.data;
    },
    async createUpdateParcelle({ dispatch }, parcelle) {
      let response = await DeclarationsService.createUpdateParcelle(parcelle);
      dispatch("sync", response.data);
      return response.data;
    },
    async fusionnerParcelles({ dispatch }, payload) {
      let response = await DeclarationsService.fusionnerParcelles(payload);
      dispatch("sync", response.data);
      return response.data;
    },
    async decouperParcelle({ dispatch }, payload) {
      let response = await DeclarationsService.decouperParcelle(payload);
      dispatch("sync", response.data);
      return response.data;
    },
    async deleteParcelle({ dispatch }, idParcelle) {
      let response = await DeclarationsService.deleteParcelle(idParcelle);
      dispatch("sync", response.data);
      return response.data;
    },
    async semerParcelle({ dispatch }, idParcelle) {
      let response = await DeclarationsService.semerParcelle(idParcelle);
      dispatch("sync", response.data);
      return response.data;
    },
    async annulerSemisParcelle({ dispatch }, idParcelle) {
      let response = await DeclarationsService.annulerSemisParcelle(idParcelle);
      dispatch("sync", response.data);
      return response.data;
    },
    async semisEnMasse({ dispatch }, payload) {
      let response = await DeclarationsService.semisEnMasse(payload);
      dispatch("sync", response.data);
      return response.data;
    },
    async retournerParcelle({ dispatch }, payload) {
      let response = await DeclarationsService.retournerParcelle(payload);
      dispatch("sync", response.data);
      return response.data;
    },
    async createUpdateEmblavements({ dispatch }, liste) {
      // FIXME Await à chaque requête pour éviter les races conditions
      // Et s'assurer que la parcelle hérite bien de la bonne culture...
      // => Créer un API endpoint atomique pour la liste à la place
      let results = [];
      for (let emblavement of liste) {
        let response = await DeclarationsService.createUpdateEmblavement(emblavement);
        dispatch("sync", response.data);
        results.push(response.data);
      }
      return results;
    },
    async deleteEmblavements({ dispatch }, liste) {
      // FIXME Await à chaque requête pour éviter les races conditions
      // Et s'assurer que la parcelle hérite bien de la bonne culture...
      // => Créer un API endpoint atomique pour la liste à la place
      let results = [];
      for (let id of liste) {
        let response = await DeclarationsService.deleteEmblavement(id);
        dispatch("sync", response.data);
        results.push(response.data);
      }
      return results;
    },
    async createUpdateMoisson({ dispatch }, moisson) {
      let response = await DeclarationsService.createUpdateMoisson(moisson);
      dispatch("sync", response.data);
      return response.data;
    },
    async deleteMoisson({ dispatch }, idMoisson) {
      let response = await DeclarationsService.deleteMoisson(idMoisson);
      dispatch("sync", response.data);
      return response.data;
    },
    async initAutoconso({ dispatch }, autoconso) {
      let response = await DeclarationsService.initAutoconso(autoconso);
      dispatch("sync", response.data);
      return response.data;
    },
    async createUpdateAutoconso({ dispatch }, autoconso) {
      let response = await DeclarationsService.createUpdateAutoconso(autoconso);
      dispatch("sync", response.data);
      return response.data;
    },
    async deleteAutoconso({ dispatch }, autoconso) {
      let response = await DeclarationsService.deleteAutoconso(autoconso);
      dispatch("sync", response.data);
      return response.data;
    },
    async createUpdateEnlevement({ dispatch }, enlevement) {
      let response = await DeclarationsService.createUpdateEnlevement(enlevement);
      dispatch("sync", response.data);
      return response.data;
    },
    async deleteEnlevement({ dispatch }, idEnlevement) {
      let response = await DeclarationsService.deleteEnlevement(idEnlevement);
      dispatch("sync", response.data);
      return response.data;
    },
  },
  mutations: {
    setCodeRecolte(state, codeRecolte) {
      state.codeRecolte = codeRecolte;
    },
    setIlots(state, liste) {
      state.ilots = liste;
    },
    setParcelles(state, liste) {
      state.parcelles = liste;
    },
    setEmblavements(state, liste) {
      state.emblavements = liste;
    },
    setCultures(state, liste) {
      state.cultures = liste;
    },
    setMoissons(state, liste) {
      state.moissons = liste;
    },
    setAutoconso(state, liste) {
      state.autoconso = liste;
    },
    setEnlevements(state, liste) {
      state.enlevements = liste;
    },
  },
};
