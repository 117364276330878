<template>
  <section>
    <b-nav-text role="button" class="w-100" @click="toggle">
      <b-row class="m-0">
        <b-col>
          <template v-if="icon">
            <component :is="icon"></component>&nbsp;
          </template>
          {{ title }}
        </b-col>
        <b-col cols="auto">
          <span v-if="visible">
            <b-icon-chevron-up></b-icon-chevron-up>
          </span>
          <span v-else>
            <b-icon-chevron-down></b-icon-chevron-down>
          </span>
        </b-col>
      </b-row>
    </b-nav-text>
    <b-collapse
      v-model="visible"
      @show="$emit('show')"
      @shown="$emit('shown')"
      @hide="$emit('hide')"
      @hidden="$emit('hidden')"
    >
      <slot>
        <em class="ml-5">Menu vide</em>
      </slot>
    </b-collapse>
  </section>
</template>

<script>
export default {
  name: "CollapsibleMenuComponent",
  /**
   * icon = icone du menu repliable
   * title = titre du menu repliable
   */
  props: ["icon", "title"],
  data: () => ({
    visible: false,
  }),
  methods: {
    show() {
      this.visible = true;
    },
    toggle() {
      this.visible = !this.visible;
    },
    hide() {
      this.visible = false;
    },
  },
};
</script>
