<template>
  <section class="d-inline-block">
    <svg
      ref="icon"
      class="font-icon"
      style="fill: none"
      width="27"
      height="24"
      viewBox="0 0 27 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.66683 8.93555H17.3335"
        stroke="#054643"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.66683 12.8711H10.3335"
        stroke="#054643"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1 6.96787V22.5071C1 22.9453 1.62833 23.1648 1.99581 22.8549L6.49163 19.0633C6.71041 18.8788 7.00717 18.7751 7.31658 18.7751H19.6667C20.9554 18.7751 22 17.8941 22 16.8072V6.96787C22 5.88105 20.9554 5 19.6667 5H3.33333C2.04467 5 1 5.88105 1 6.96787Z"
        stroke="#054643"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <circle cx="23" cy="4" r="4" fill="#F8BB67" />
    </svg>
    <b-tooltip :target="() => $refs['icon']" triggers="hover" variant="primary">
      <span class="commentaire">
        <slot></slot>
      </span>
    </b-tooltip>
  </section>
</template>

<script>
export default {
  name: "ExistingCommentIcon",
};
</script>
