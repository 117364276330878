<template>
  <b-navbar class="d-none d-md-flex flex-column menu-desktop">
    <b-navbar-brand class="w-100 m-0 p-1 logo">
      <b-link :to="{ name: 'accueil' }">
        <b-img fluid :src="logoSociete" alt="Logo de la société"></b-img>
      </b-link>
    </b-navbar-brand>
    <b-nav vertical>
      <b-row
        v-if="$store.state.environment !== 'prod'"
        class="m-0"
        style="background-color: orange"
      >
        <b-col>
          <b-nav-text class="w-100 text-center">
            <strong style="color: blue">
              ENVIRONNEMENT DE
              {{ $store.state.environment.toUpperCase() }}
            </strong>
          </b-nav-text>
        </b-col>
      </b-row>
      <b-row class="m-0">
        <b-col>
          <b-nav-text class="w-100 text-center">
            <ExploitationMenuIcon></ExploitationMenuIcon>&nbsp;
            {{ exploitationCourante.raisonSociale }}
          </b-nav-text>
        </b-col>
      </b-row>
    </b-nav>
    <section class="w-100 contenu-scrollable" style="overflow: auto">
      <b-nav vertical>
        <b-nav-item :to="{ name: 'accueil' }">
          <AccueilMenuIcon></AccueilMenuIcon>&nbsp; Accueil
        </b-nav-item>
        <b-nav-item :to="{ name: 'exploitations' }">
          <ExploitationMenuIcon></ExploitationMenuIcon>&nbsp; Mes exploitations
        </b-nav-item>
        <!-- Menu coopérative -->
        <CollapsibleMenuComponent
          v-if="societe"
          icon="CooperativeMenuIcon"
          :title="societe.libelle"
          ref="menuCooperative"
          @show="closeMenusExcept('menuCooperative')"
        >
          <b-nav-item
            v-for="page in getPages(societe.code)"
            :key="page.id"
            :to="{ name: 'page', params: { id: page.id } }"
          >
            {{ page.attributes.titre }}
          </b-nav-item>
        </CollapsibleMenuComponent>
        <!-- Menu campagnes -->
        <CollapsibleMenuComponent
          icon="DeclarationsMenuIcon"
          title="Déclarations"
          ref="menuDeclarations"
          @show="closeMenusExcept('menuDeclarations')"
        >
          <b-nav-item
            v-for="recolte in recoltes"
            :key="recolte"
            :to="{ name: 'recolte', params: { codeRecolte: recolte } }"
          >
            Campagne {{ recolte }}
          </b-nav-item>
        </CollapsibleMenuComponent>
        <!-- Menu appro -->
        <CollapsibleMenuComponent
          icon="ApproMenuIcon"
          title="Appro"
          ref="menuAppro"
          @show="closeMenusExcept('menuAppro')"
        >
          <b-nav-item :to="{ name: 'catalogues' }">Catalogues</b-nav-item>
          <b-nav-item
            v-if="accesCommandeAppro"
            :to="{ name: 'commandes-appro-extranet' }"
          >
            Commandes extranet
          </b-nav-item>
          <b-nav-item
            v-if="accesCommandeAppro"
            :to="{ name: 'bons-commande-appro' }"
          >
            Bons de commande
          </b-nav-item>
          <b-nav-item :to="{ name: 'bons-livraison-appro' }">
            Bons de livraison
          </b-nav-item>
        </CollapsibleMenuComponent>
        <!-- Menu collecte -->
        <CollapsibleMenuComponent
          icon="CollecteMenuIcon"
          title="Collecte"
          ref="menuCollecte"
          @show="closeMenusExcept('menuCollecte')"
        >
          <b-nav-item :to="{ name: 'reglements-campagne' }">
            R&egrave;glements de campagne
          </b-nav-item>
          <b-nav-item
            v-if="accesEchantillonsMoisson"
            :to="{ name: 'analyses-moisson' }"
          >
            Echantillons moisson
          </b-nav-item>
          <b-nav-item :to="{ name: 'bons-apport' }">Bons d'apport</b-nav-item>
          <b-nav-item :to="{ name: 'contrats-apport' }">
            Suivi de la collecte
          </b-nav-item>
          <b-nav-item v-if="accesEgalim" :to="{ name: 'prix-fin-campagne' }">
            Prix fin de campagne
          </b-nav-item>
        </CollapsibleMenuComponent>
        <!-- Menu administratif -->
        <CollapsibleMenuComponent
          icon="AdministratifMenuIcon"
          title="Administratif"
          ref="menuAdministratif"
          @show="closeMenusExcept('menuAdministratif')"
        >
          <b-nav-item :to="{ name: 'certificats' }">
            Certificats Bio
          </b-nav-item>
          <b-nav-item :to="{ name: 'releve-compte' }">
            Relev&eacute; de compte
          </b-nav-item>
          <b-nav-item
            v-if="accesCapitalSocial"
            :to="{ name: 'situation-capsoc' }"
          >
            Capital social
          </b-nav-item>
        </CollapsibleMenuComponent>
        <!-- Menu communication -->
        <CollapsibleMenuComponent
          v-show="isSalarie"
          icon="BIconMegaphoneFill"
          title="Communication"
          ref="menuCommunication"
          @show="closeMenusExcept('menuCommunication')"
        >
          <b-nav-item :to="{ name: 'com-assistant' }">
            Nouvel envoi
          </b-nav-item>
          <b-nav-item :to="{ name: 'com-templates' }">Templates</b-nav-item>
          <b-nav-item :to="{ name: 'com-historique' }">Historique</b-nav-item>
        </CollapsibleMenuComponent>
        <section v-if="isSalarie">
          <b-nav-item :to="{ name: 'referentiel' }">
            <b-icon-ui-checks></b-icon-ui-checks>&nbsp;
            R&eacute;f&eacute;rentiel
          </b-nav-item>
          <b-nav-item :to="{ name: 'backoffice' }">
            <b-icon-tools></b-icon-tools>&nbsp; Backoffice
          </b-nav-item>
        </section>
      </b-nav>
    </section>
    <b-nav vertical class="mt-auto">
      <b-nav-item class="exploitation-link" :to="{ name: 'compte' }">
        <ExploitationCardComponent></ExploitationCardComponent>
      </b-nav-item>
    </b-nav>
  </b-navbar>
</template>

<script>
import CollapsibleMenuComponent from "./CollapsibleMenuComponent.vue";
import ExploitationCardComponent from "./ExploitationCardComponent.vue";
import UtilsService from "../../services/utils.service";
export default {
  name: "MenuDesktopComponent",
  components: {
    CollapsibleMenuComponent,
    ExploitationCardComponent,
  },
  data() {
    return {
      // Nom des routes gérées par chaque menu
      collapses: this.$store.state.structureMenu,
    };
  },
  computed: {
    recoltes() {
      return this.$store.state.ref.recoltes.map((r) => r.codeRecolte);
    },
    exploitationCourante() {
      return this.$store.getters["expl/adherentCourant"];
    },
    societe() {
      return this.$store.getters["societe"](
        this.exploitationCourante.codeSociete
      );
    },
    logoSociete() {
      return this.societe.logoBlanc ?? this.societe.logo;
    },
    isSalarie() {
      return this.$store.getters["auth/isSalarie"];
    },
    accesCommandeAppro() {
      return this.$store.getters["ref/commandeAppro"](
        this.exploitationCourante.codeSociete
      );
    },
    accesEchantillonsMoisson() {
      return this.$store.getters["ref/echantillonsMoisson"](
        this.exploitationCourante.codeSociete
      );
    },
    accesEgalim() {
      return (
        this.exploitationCourante.codeTypeTiers === "A" &&
        this.$store.getters["ref/egalim"](this.exploitationCourante.codeSociete)
      );
    },
    accesCapitalSocial() {
      return (
        this.exploitationCourante.codeTypeTiers === "A" &&
        this.$store.getters["ref/capitalSocial"](
          this.exploitationCourante.codeSociete
        )
      );
    },
  },
  mounted() {
    this.openMenuActif();
    this.$watch(
      () => this.$route.path,
      () => this.openMenuActif()
    );
    this.$watch(
      () => this.$route.params,
      () => this.openMenuActif()
    );
  },
  methods: {
    getPages(codeSociete) {
      return this.$store.getters["strapi/pages"](codeSociete).sort(
        UtilsService.sortByNumberProperty("attributes.ordre")
      );
    },
    openMenuActif() {
      for (const name in this.collapses) {
        let activeMenu = this.collapses[name].includes(this.$route.name);
        if (activeMenu) {
          this.$refs[name].show();
        } else {
          this.$refs[name].hide();
        }
      }
    },
    closeMenusExcept(except) {
      for (const name in this.collapses) {
        if (name !== except) {
          this.$refs[name].hide();
        }
      }
    },
  },
};
</script>
