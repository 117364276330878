<template>
  <svg
    class="font-icon"
    width="31"
    height="31"
    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="15.5" cy="15.5" r="15.5" fill="#F8BB67" />
    <circle cx="15.5" cy="10.5" r="6" fill="#F8BB67" stroke="#054643" />
    <path
      d="M5 25C6.21739 23 10.0217 19 15.5 19C20.9783 19 24.7826 23 26 25"
      fill="#F8BB67"
      stroke="#054643"
    />
  </svg>
</template>

<script>
export default {
  name: "AccountMenuIcon",
};
</script>
