import { apiPwfb } from "../api-pwfb";

class DeclarationsService {
  getDeclarations(codeTiers, codeRecolte) {
    return apiPwfb.get(`/exploitation/${codeTiers}/declarations/${codeRecolte}`);
  }
  // ILOTS ET PARCELLES
  importerDossierTelepac(codeTiers, codeRecolte, formData) {
    return apiPwfb.post(
      `/exploitation/${codeTiers}/import-telepac/${codeRecolte}/dossier-xml`,
      formData,
      { headers: { "Content-Type": "multipart/form-data" } }
    );
  }
  importerDescriptifParcellesTelepac(codeTiers, codeRecolte, formData) {
    return apiPwfb.post(
      `/exploitation/${codeTiers}/import-telepac/${codeRecolte}/descriptif-parcelles-txt`,
      formData,
      { headers: { "Content-Type": "multipart/form-data" } }
    );
  }
  copierParcellaire(codeTiers, codeRecolte) {
    return apiPwfb.get(`/exploitation/${codeTiers}/copie-parcellaire/${codeRecolte}`);
  }
  annulerDeclarations(codeRecolte, codeTiers) {
    return apiPwfb.get(`/exploitation/${codeTiers}/annuler-declarations/${codeRecolte}`);
  }
  transfererDeclarations(codeRecolte, codeTiersOrigine, codeTiersDestination) {
    return apiPwfb.get(`/exploitation/${codeTiersOrigine}/transferer-declarations/${codeRecolte}/vers/${codeTiersDestination}`);
  }
  createUpdateIlot(ilot) {
    return ilot.id
      ? apiPwfb.post("/ilot/update", ilot)
      : apiPwfb.post("/ilot/create", ilot);
  }
  deleteIlot(idIlot) {
    return apiPwfb.get(`/ilot/${idIlot}/delete`);
  }
  createUpdateParcelle(parcelle) {
    return parcelle.id
      ? apiPwfb.post("/parcelle/update", parcelle)
      : apiPwfb.post("/parcelle/create", parcelle);
  }
  deleteParcelle(idParcelle) {
    return apiPwfb.get(`/parcelle/${idParcelle}/delete`);
  }
  fusionnerParcelles(payload) {
    return apiPwfb.post("/parcelle/fusionner", payload)
  }
  decouperParcelle(payload) {
    return apiPwfb.post(`/parcelle/${payload.idParcelle}/decouper`, payload);
  }
  getHistoriqueParcelle(idParcelle) {
    return apiPwfb.get(`/parcelle/${idParcelle}/historique`);
  }
  semerParcelle(idParcelle) {
    return apiPwfb.get(`/parcelle/${idParcelle}/semer`);
  }
  annulerSemisParcelle(idParcelle) {
    return apiPwfb.get(`/parcelle/${idParcelle}/annuler-semis`);
  }
  semisEnMasse(payload) {
    return apiPwfb.post(`/semis-en-masse`, payload);
  }
  retournerParcelle(payload) {
    return apiPwfb.post(`/parcelle/${payload.idParcelle}/retourner`, payload);
  }
  // EMBLAVEMENTS
  createUpdateEmblavement(emblavement) {
    return emblavement.id
      ? apiPwfb.post("/emblavement/update", emblavement)
      : apiPwfb.post("/emblavement/create", emblavement);
  }
  deleteEmblavement(idEmblavement) {
    return apiPwfb.get(`/emblavement/${idEmblavement}/delete`);
  }
  // RESULTATS DE MOISSON
  createUpdateMoisson(moisson) {
    return moisson.id
      ? apiPwfb.post("/moisson/update", moisson)
      : apiPwfb.post("/moisson/create", moisson);
  }
  deleteMoisson(idResultatMoisson) {
    return apiPwfb.get(`/moisson/${idResultatMoisson}/delete`);
  }
  // AUTOCONSOMMATION
  initAutoconso(autoconso) {
    return apiPwfb.post("/autoconso/init", autoconso);
  }
  createUpdateAutoconso(autoconso) {
    return autoconso.id
      ? apiPwfb.post("/autoconso/update", autoconso)
      : apiPwfb.post("/autoconso/create", autoconso);
  }
  deleteAutoconso(autoconso) {
    return apiPwfb.post("/autoconso/update", Object.assign({}, autoconso, { codeMotif: "NONE" }));
  }
  // ENLEVEMENTS
  createUpdateEnlevement(enlevement) {
    return enlevement.id
      ? apiPwfb.post("/enlevement/update", enlevement)
      : apiPwfb.post("/enlevement/create", enlevement);
  }
  deleteEnlevement(idEnlevement) {
    return apiPwfb.get(`/enlevement/${idEnlevement}/delete`);
  }
}

export default new DeclarationsService();
