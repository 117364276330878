import { apiPwfb } from "../api-pwfb";

class AuthService {
  login(payload) {
    return apiPwfb.post("/auth/login", payload);
  }
  refresh() {
    return apiPwfb.get("/auth/refresh");
  }
}

export default new AuthService();
