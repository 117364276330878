<template>
  <svg
    class="font-icon"
    width="19"
    height="19"
    viewBox="0 0 19 19"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1.1875 16.625H5.9375V17.8125H1.1875V16.625Z" />
    <path
      d="M10.0938 17.2187C10.0047 17.2187 9.91682 17.1987 9.83658 17.1601C9.75633 17.1216 9.68578 17.0655 9.63015 16.996L7.43375 14.25H1.1875V13.0625H7.71875C7.80778 13.0625 7.89568 13.0825 7.97592 13.1211C8.05617 13.1596 8.12672 13.2157 8.18235 13.2853L10.0503 15.62L13.1622 10.9519C13.2149 10.8729 13.2858 10.8076 13.3689 10.7617C13.452 10.7157 13.5449 10.6904 13.6398 10.6877C13.7347 10.6851 13.8289 10.7053 13.9144 10.7465C13.9999 10.7878 14.0743 10.849 14.1312 10.925L15.7344 13.0625H17.8125V14.25H15.4375C15.3453 14.25 15.2544 14.2286 15.1719 14.1874C15.0895 14.1461 15.0178 14.0863 14.9625 14.0125L13.6846 12.3088L10.5876 16.9543C10.5359 17.032 10.4665 17.0964 10.3851 17.1423C10.3038 17.1881 10.2128 17.2141 10.1195 17.2181L10.0938 17.2187Z"
    />
    <path
      d="M6.53125 9.5V6.53125H7.125C7.75467 6.53054 8.35835 6.28009 8.8036 5.83485C9.24884 5.3896 9.49929 4.78592 9.5 4.15625V2.375H7.71875C7.1108 2.37597 6.52665 2.61133 6.08784 3.0321C5.78032 2.47387 5.32876 2.0083 4.78018 1.68388C4.23161 1.35945 3.60608 1.18804 2.96875 1.1875H1.1875V2.96875C1.18854 3.91327 1.5642 4.8188 2.23208 5.48667C2.89995 6.15455 3.80548 6.53021 4.75 6.53125H5.34375V9.5H1.1875V10.6875H9.5V9.5H6.53125Z"
    />
  </svg>
</template>

<script>
export default {
  name: "DeclarationsMenuIcon",
};
</script>
