<template>
  <div class="d-none d-md-block footer-desktop">
    <b-row>
      <b-col class="text-center">
        <b-link :to="{ name: 'faq' }">Foire aux questions</b-link>
      </b-col>
      <b-col class="text-center">
        <b-link :to="{ name: 'changelog' }">Historique des changements</b-link>
      </b-col>
      <b-col class="text-center">
        <b-link :to="{ name: 'ecoconception' }">
          Conception/r&eacute;alisation
        </b-link>
      </b-col>
      <b-col class="text-center">
        <b-link :to="{ name: 'mentions' }">Mentions l&eacute;gales</b-link>
      </b-col>
    </b-row>
    <b-row align-h="center">
      <em>
        &copy; 2022 Union Fermes Bio - Contact :
        <b-link href="mailto:idees_extranet@fermesbio.fr">
          idees_extranet@fermesbio.fr
        </b-link>
      </em>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "FooterDesktopComponent",
};
</script>
