<template>
  <svg
    class="font-icon"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.57143 0C1.15127 0 0 1.15127 0 2.57143V15.4286C0 16.8488 1.15127 18 2.57143 18H15.4286C16.8488 18 18 16.8488 18 15.4286V2.57143C18 1.15127 16.8488 0 15.4286 0H2.57143ZM5.78571 5.46429C5.78571 5.99684 5.35398 6.42857 4.82143 6.42857C4.28887 6.42857 3.85714 5.99684 3.85714 5.46429C3.85714 4.93173 4.28887 4.5 4.82143 4.5C5.35398 4.5 5.78571 4.93173 5.78571 5.46429ZM4.82143 10.2857C4.28887 10.2857 3.85714 9.85397 3.85714 9.32143C3.85714 8.78887 4.28887 8.35714 4.82143 8.35714C5.35398 8.35714 5.78571 8.78887 5.78571 9.32143C5.78571 9.85397 5.35398 10.2857 4.82143 10.2857ZM5.78571 13.1786C5.78571 13.7111 5.35398 14.1429 4.82143 14.1429C4.28887 14.1429 3.85714 13.7111 3.85714 13.1786C3.85714 12.646 4.28887 12.2143 4.82143 12.2143C5.35398 12.2143 5.78571 12.646 5.78571 13.1786ZM8.35714 5.14286H13.5C13.855 5.14286 14.1429 5.43068 14.1429 5.78571C14.1429 6.14075 13.855 6.42857 13.5 6.42857H8.35714C8.00211 6.42857 7.71429 6.14075 7.71429 5.78571C7.71429 5.43068 8.00211 5.14286 8.35714 5.14286ZM7.71429 9.64286C7.71429 9.28787 8.00211 9 8.35714 9H13.5C13.855 9 14.1429 9.28787 14.1429 9.64286C14.1429 9.99784 13.855 10.2857 13.5 10.2857H8.35714C8.00211 10.2857 7.71429 9.99784 7.71429 9.64286ZM8.35714 12.8571H13.5C13.855 12.8571 14.1429 13.145 14.1429 13.5C14.1429 13.855 13.855 14.1429 13.5 14.1429H8.35714C8.00211 14.1429 7.71429 13.855 7.71429 13.5C7.71429 13.145 8.00211 12.8571 8.35714 12.8571Z"
    />
  </svg>
</template>

<script>
export default {
  name: "ApproMenuIcon",
};
</script>
