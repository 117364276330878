<template>
  <div v-if="loading" class="text-center text-warning my-3">
    <b-spinner class="align-middle"></b-spinner>
    <br />
    <strong>Chargement en cours...</strong>
  </div>
  <div v-else-if="loggedIn">
    <template v-if="affichageMobile">
      <!-- Menu mobile (fixed-top) -->
      <MenuMobileComponent></MenuMobileComponent>
      <div class="main-container">
        <div class="main-content">
          <router-view />
        </div>
      </div>
      <!-- Pied de page mobile (fixed-bottom) -->
      <FooterMobileComponent></FooterMobileComponent>
    </template>
    <template v-else>
      <!-- Menu desktop (fixed-left) -->
      <MenuDesktopComponent></MenuDesktopComponent>
      <div class="main-container">
        <div class="main-content">
          <router-view />
        </div>
        <!-- Pied de page desktop (sticky-bottom) -->
        <FooterDesktopComponent></FooterDesktopComponent>
      </div>
    </template>
  </div>
  <div v-else class="main-content">
    <router-view />
  </div>
</template>

<script>
import MenuDesktopComponent from "./components/menus/MenuDesktopComponent.vue";
import FooterDesktopComponent from "./components/menus/FooterDesktopComponent.vue";
import MenuMobileComponent from "./components/menus/MenuMobileComponent.vue";
import FooterMobileComponent from "./components/menus/FooterMobileComponent.vue";
import UtilsService from "./services/utils.service";
export default {
  components: {
    MenuDesktopComponent,
    FooterDesktopComponent,
    MenuMobileComponent,
    FooterMobileComponent,
  },
  data: () => ({
    loading: true,
  }),
  computed: {
    affichageMobile() {
      return this.$store.getters["affichageMobile"];
    },
    loggedIn() {
      return this.$store.getters["auth/loggedIn"];
    },
  },
  async created() {
    try {
      if (this.loggedIn) {
        await this.$store.dispatch("auth/mount");
      } else {
        await this.$store.dispatch("auth/logout");
      }
    } finally {
      this.loading = false;
    }
  },
  mounted() {
    this.onResize();
    window.addEventListener(
      "resize",
      UtilsService.debounce(this, this.onResize, 200)
    );
  },
  unmounted() {
    window.removeEventListener(
      "resize",
      UtilsService.debounce(this, this.onResize, 200)
    );
  },
  methods: {
    onResize() {
      this.$store.dispatch("refreshCurrentBreakpoint", window.innerWidth);
    },
  },
};
</script>
