import Vue from 'vue';
import Vuex from "vuex";
import { auth } from "./auth.module";
import { expl } from "./exploitation.module";
import { decl } from "./declarations.module";
import { ref } from "./referentiel.module";
import { strapi } from "./strapi.module";
import { tab } from "./tables.module";

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    environment: "dev",
    union: { code: "FERMESBIO", libelle: "Fermes Bio", logo: "/img/logos/logo_fermes_bio.png", logoBlanc: null, },
    cooperatives: [
      { code: "BIOCER", libelle: "Biocer", hostname: "biocer.fr", logo: "/img/logos/logo_biocer.png", logoBlanc: "/img/logos/logo_biocer_blanc.png", },
      { code: "COCEBI", libelle: "COCEBI", hostname: "cocebi.fr", logo: "/img/logos/logo_cocebi.png", logoBlanc: "/img/logos/logo_cocebi_blanc.png", },
      { code: "PROBIOLOR", libelle: "Probiolor", hostname: "probiolor.com", logo: "/img/logos/logo_probiolor.svg", logoBlanc: "/img/logos/logo_probiolor_blanc.svg", },
    ],
    breakpoints: null,
    currentBreakpoint: "xl",
    // Nom des routes gérées par chaque menu
    structureMenu: {
      menuCooperative: ["page", "actualite", "evenement", "assemblees-generales", "assemblee-generale",],
      menuDeclarations: ["recolte", "parcelles", "emblavements", "autoconsommation", "enlevements", "moisson",],
      menuAppro: ["catalogues", "listes-produits", "commande-appro", "commandes-appro-extranet", "bons-commande-appro", "bons-livraison-appro",],
      menuCollecte: ["reglements-campagne", "analyses-moisson", "bons-apport", "contrats-apport", "prix-fin-campagne",],
      menuAdministratif: ["certificats", "releve-compte", "situation-capsoc",],
      menuCommunication: ["com-assistant", "com-templates", "com-historique",],
    }
  },
  getters: {
    cooperative: (state) => (codeSociete) =>
      state.cooperatives.find((t) => t.code === codeSociete),
    societe: (state, getters) => (codeSociete) =>
      getters.cooperative(codeSociete) ?? state.union,
    societeFromHostname: (state) => (hostname) =>
      state.cooperatives.find(c => hostname.toLowerCase().includes(c.hostname)) ?? state.union,
    affichageMobile: (state) =>
      ["sm", "xs"].includes(state.currentBreakpoint),
    affichageTablette: (state) =>
      ["lg", "md"].includes(state.currentBreakpoint),
    affichageDesktop: (state) =>
      ["xxl", "xl"].includes(state.currentBreakpoint),
    breakpointDown: (state) => (targetBreakpoint) =>
      state.breakpoints &&
      state.breakpoints.get(state.currentBreakpoint) <= state.breakpoints.get(targetBreakpoint),
    breakpointUp: (state) => (targetBreakpoint) =>
      state.breakpoints &&
      state.breakpoints.get(state.currentBreakpoint) >= state.breakpoints.get(targetBreakpoint),
  },
  actions: {
    async onLogin({ dispatch }) {
      await Promise.all([
        dispatch("expl/load"),
        dispatch("ref/revalidate"),
      ]);
      // Attendre qu'on ait récupéré les paramètres
      // Pour avoir le token d'accès à Strapi
      dispatch("strapi/loadPages");
    },
    async onMount({ dispatch }) {
      await Promise.all([
        dispatch("expl/load"),
        dispatch("ref/revalidate"),
      ]);
      // Attendre qu'on ait récupéré les paramètres
      // Pour avoir le token d'accès à Strapi
      dispatch("strapi/loadPages");
    },
    onLogout({ dispatch }) {
      return Promise.all([
        dispatch("expl/clear"),
        dispatch("ref/clear"),
        dispatch("decl/clear"),
        dispatch("strapi/clear"),
      ]);
    },
    refreshBreakpoints({ commit }) {
      // On initialise la liste à partir des variables CSS --breakpoint-*
      let breakpoints = new Map();
      let rootCss = getComputedStyle(document.documentElement);
      ["xxl", "xl", "lg", "md", "sm", "xs"].forEach(b => {
        let cssValue = rootCss.getPropertyValue(`--breakpoint-${b}`);
        if (cssValue) {
          breakpoints.set(b, parseInt(cssValue.replace(/[^\d]/g, "")));
        }
      });
      commit("setBreakpoints", breakpoints);
    },
    refreshCurrentBreakpoint({ state, dispatch, commit }, width) {
      // Initialiser la liste des breakpoints si nécessaire
      if (!state.breakpoints) {
        dispatch("refreshBreakpoints");
      }

      // Récupérer le breakpoint courant
      for (let [code, minWidth] of state.breakpoints) {
        if (width >= minWidth) {
          commit("setCurrentBreakpoint", code);
          return;
        }
      }

      // Sinon utiliser le breakpoint desktop
      console.error("Erreur lors de la détection du breakpoint, xl par défaut");
      commit("setCurrentBreakpoint", "xl");
    },
  },
  mutations: {
    setBreakpoints(state, map) {
      state.breakpoints = map;
    },
    setCurrentBreakpoint(state, breakpoint) {
      state.currentBreakpoint = breakpoint;
    },
  },
  modules: { auth, expl, decl, ref, strapi, tab, },
});
