/**
 * Parametrage des validateurs de formulaire
 */

import { extend } from "vee-validate";
import { required, email, min, max, length, min_value, max_value, is } from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "Ce champ est obligatoire",
});

extend("email", {
  ...email,
  message: "L'email saisi est invalide",
});

extend("min", {
  ...min,
  message: (fieldName, params) => `Vous devez saisir au minimum ${params.length} caractères`,
});

extend("max", {
  ...max,
  message: (fieldName, params) => `Vous devez saisir au maximum ${params.length} caractères`,
});

extend("length", {
  ...length,
  message: (fieldName, params) => `Vous devez saisir exactement ${params.length} caractères`,
});

extend("min_value", {
  ...min_value,
  message: (fieldName, params) => `La valeur minimale autorisée est ${params.min}`,
});

extend("max_value", {
  ...max_value,
  message: (fieldName, params) => `La valeur maximale autorisée est ${params.max}`,
});

extend("multiple", {
  params: ["factor"],
  validate: function (value, params) {
    return Math.abs((value ?? 0) % params.factor) < 0.001;
  },
  message: (fieldName, params) => `Doit être un multiple de ${params.factor}`,
});

extend("password", {
  validate: (value) => [
    /^.{12,}$/, // 12+ caractères
    /^.*[A-Z\u00C0-\u00D6\u00D8-\u00DD]+.*$/, // 1+ majuscules (accents compris)
    /^.*[a-z\u00E0-\u00F6\u00F8-\u00FF]+.*$/, // 1+ minuscules (accents compris)
    /^.*[0-9]+.*$/, // 1+ chiffres
    // /^.*[\u0021-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E]+.*$/, // 0 caractères spéciaux
  ].reduce((acc, next) => acc && next.test(value), true),
  message:
    "Votre nouveau mot de passe doit contenir au moins 12 caractères dont 1 majuscule, 1 minuscule et 1 chiffre",
});

extend("confirmation", {
  ...is,
  message: "Le champ de confirmation doit être égal à votre nouveau mot de passe",
});

extend("date_between", {
  params: ["from", "to"],
  validate: function (value, params) {
    return params.from.localeCompare(value) <= 0 && params.to.localeCompare(value) >= 0;
  },
  message: (fieldName, params) => `La date doit être comprise entre ${params.from} et ${params.to}`,
});
