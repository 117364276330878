<template>
  <section class="d-inline-block">
    <svg
      ref="icon"
      class="font-icon"
      style="fill: none"
      width="23"
      height="20"
      viewBox="0 0 23 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 8H15"
        stroke="#054643"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 11V5"
        stroke="#054643"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1 2.96787V18.5071C1 18.9453 1.62833 19.1648 1.99581 18.8549L6.49163 15.0633C6.71041 14.8788 7.00717 14.7751 7.31658 14.7751H19.6667C20.9554 14.7751 22 13.8941 22 12.8072V2.96787C22 1.88105 20.9554 1 19.6667 1H3.33333C2.04467 1 1 1.88105 1 2.96787Z"
        stroke="#054643"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <b-tooltip :target="() => $refs['icon']" triggers="hover" variant="primary">
      <slot>Ajouter un commentaire</slot>
    </b-tooltip>
  </section>
</template>

<script>
export default {
  name: "NewCommentIcon",
};
</script>
