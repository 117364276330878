
/**
 * Import global des icones SVG Fermes Bio
 */

import Vue from "vue";

// Icones du menu desktop
import AccueilMenuIcon from "./components/icons/AccueilMenuIcon";
import ExploitationMenuIcon from "./components/icons/ExploitationMenuIcon";
import CooperativeMenuIcon from "./components/icons/CooperativeMenuIcon";
import ApproMenuIcon from "./components/icons/ApproMenuIcon";
import DeclarationsMenuIcon from "./components/icons/DeclarationsMenuIcon";
import CollecteMenuIcon from "./components/icons/CollecteMenuIcon";
import AdministratifMenuIcon from "./components/icons/AdministratifMenuIcon";

Vue.component("AccueilMenuIcon", AccueilMenuIcon);
Vue.component("ExploitationMenuIcon", ExploitationMenuIcon);
Vue.component("CooperativeMenuIcon", CooperativeMenuIcon);
Vue.component("ApproMenuIcon", ApproMenuIcon);
Vue.component("DeclarationsMenuIcon", DeclarationsMenuIcon);
Vue.component("CollecteMenuIcon", CollecteMenuIcon);
Vue.component("AdministratifMenuIcon", AdministratifMenuIcon);

// Icones de la barre d'onglets
import ParcellesTabIcon from "./components/icons/ParcellesTabIcon";
import EmblavementsTabIcon from "./components/icons/EmblavementsTabIcon";
import AutoconsoTabIcon from "./components/icons/AutoconsoTabIcon";
import RecolteTabIcon from "./components/icons/RecolteTabIcon";

Vue.component("ParcellesTabIcon", ParcellesTabIcon);
Vue.component("EmblavementsTabIcon", EmblavementsTabIcon);
Vue.component("AutoconsoTabIcon", AutoconsoTabIcon);
Vue.component("RecolteTabIcon", RecolteTabIcon);

// Icones de la barre d'actions
import CancelIcon from "./components/icons/CancelIcon";
import ExistingCommentIcon from "./components/icons/ExistingCommentIcon";
import NewCommentIcon from "./components/icons/NewCommentIcon";
import ValidateIcon from "./components/icons/ValidateIcon";

Vue.component("CancelIcon", CancelIcon);
Vue.component("ExistingCommentIcon", ExistingCommentIcon);
Vue.component("NewCommentIcon", NewCommentIcon);
Vue.component("ValidateIcon", ValidateIcon);

// Autres icones
import MelangeIcon from "./components/icons/MelangeIcon";
import MoissonneuseIcon from "./components/icons/MoissonneuseIcon";

Vue.component("MelangeIcon", MelangeIcon);
Vue.component("MoissonneuseIcon", MoissonneuseIcon);
