import ExploitationService from "../services/exploitation.service";
import UtilsService from "../services/utils.service";

export const expl = {
  namespaced: true,
  state: {
    exploitations: JSON.parse(localStorage.getItem("expl/exploitations")) || [],
    exploitationCourante:
      JSON.parse(localStorage.getItem("expl/exploitationCourante")) || null,
    portefeuille: JSON.parse(localStorage.getItem("expl/portefeuille")) || [],
    adherentCourant:
      JSON.parse(localStorage.getItem("expl/adherentCourant")) || null,
    stockages: JSON.parse(localStorage.getItem("expl/stockages")) || [],
    typesDocument: {
      BON_COMMANDE_APPRO: { getDownloadToken: ExploitationService.getPieceJointeBonCommandeAppro, route: "bons-commande-appro" },
      BON_LIVRAISON_APPRO: { getDownloadToken: ExploitationService.getPieceJointeBonLivraisonAppro, route: "bons-livraison-appro" },
      ANALYSE_MOISSON: { getDownloadToken: ExploitationService.getPieceJointeContratApport, route: "contrats-apport" },
      BON_APPORT: { getDownloadToken: ExploitationService.getPieceJointeBonApport, route: "bons-apport" },
      RECAP_EGALIM: { getDownloadToken: ExploitationService.getPieceJointeRecapitulatifEgalim, route: "egalim" },
      FACTURE: { getDownloadToken: ExploitationService.getPieceJointeMouvementFinancier, route: "releve-compte" },
      CERTIFICAT: { getDownloadToken: ExploitationService.getPieceJointeCertificat, route: "certificats" },
      REPONSE_AG: { getDownloadToken: ExploitationService.getPieceJointeReponse, route: undefined },
      RECAP_CAPSOC: { getDownloadToken: ExploitationService.getPieceJointeRecapitulatifCapsoc, route: "situation-capsoc" },
    },
  },
  getters: {
    getExploitation: (state) => (codeTiers) =>
      state.exploitations.find((e) => e.codeTiers === codeTiers) ||
      state.portefeuille.find((e) => e.codeTiers === codeTiers),
    /**
     * Getter à utiliser lorsque seule l'exploitation de l'utilisateur nous intéresse
     */
    exploitationCourante: (state, getters) =>
      getters.getExploitation(state.exploitationCourante),
    /**
     * Getter à utiliser sur tous les écrans où l'adhérent simulé prend le pas sur l'exploitation de l'utilisateur
     */
    adherentCourant: (state, getters) =>
      getters.getExploitation(state.adherentCourant ?? state.exploitationCourante),
    contactCourant: (state, _, rootState) => {
      let codeTiers = state.exploitationCourante;
      return rootState.auth.user?.contacts?.find((c) => c.codeTiers === codeTiers);
    },
    prenomNom: (_, getters) => {
      let contact = getters.contactCourant;
      return `${contact?.prenom ?? ""} ${contact?.nom ?? ""}`;
    },
    societeCourante: (state, getters) =>
      getters.exploitationCourante?.codeSociete,
  },
  actions: {
    load({ dispatch }) {
      return Promise.all([
        dispatch("loadExploitations"),
        dispatch("loadPortefeuille"),
      ]).catch(() => dispatch("clear"));
    },
    loadExploitations({ state, commit, dispatch }) {
      return ExploitationService.getExploitations().then(
        (response) => {
          let exploitations = response.data.sort((e1, e2) => {
            let e1Sort = `${e1.codeTypeTiers === "S" ? "0" : "1"}${e1.codeTiers}`;
            let e2Sort = `${e2.codeTypeTiers === "S" ? "0" : "1"}${e2.codeTiers}`;
            return e1Sort.localeCompare(e2Sort);
          });
          commit("setExploitations", exploitations);
          commit("setExploitationCourante",
            exploitations.find((e) => e.codeTiers === state.exploitationCourante)
              ? state.exploitationCourante
              : exploitations[0].codeTiers);
        },
        () => dispatch("clear")
      );
    },
    loadPortefeuille({ state, rootGetters, commit, dispatch }) {
      // Si l'utilisateur n'est pas salarié, on ne fait rien
      if (!rootGetters['auth/isSalarie']) {
        commit("setPortefeuille", []);
        commit("setAdherentCourant", null);
        return;
      }
      // Si l'utilisateur est salarié, appel à l'API
      return ExploitationService.getPortefeuille().then(
        (response) => {
          let portefeuille = response.data.sort(UtilsService.sortByStringProperty("codeTiers"));
          commit("setPortefeuille", portefeuille);
          commit("setAdherentCourant",
            portefeuille.find((e) => e.codeTiers === state.adherentCourant)
              ? state.adherentCourant
              : null);
        },
        () => dispatch("clear")
      );
    },
    clear({ commit }) {
      commit("setExploitations", []);
      commit("setExploitationCourante", null);
      commit("setPortefeuille", []);
      commit("setAdherentCourant", null);
    },
  },
  mutations: {
    setExploitations(state, liste) {
      localStorage.setItem("expl/exploitations", JSON.stringify(liste));
      state.exploitations = liste;
    },
    setExploitationCourante(state, codeTiers) {
      localStorage.setItem(
        "expl/exploitationCourante",
        JSON.stringify(codeTiers)
      );
      state.exploitationCourante = codeTiers;
    },
    setPortefeuille(state, liste) {
      localStorage.setItem("expl/portefeuille", JSON.stringify(liste));
      state.portefeuille = liste;
    },
    setAdherentCourant(state, codeTiers) {
      localStorage.setItem(
        "expl/adherentCourant",
        JSON.stringify(codeTiers)
      );
      state.adherentCourant = codeTiers;
    },
  },
};
