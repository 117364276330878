<template>
  <svg
    class="font-icon svg-element"
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    @click="$emit('click')"
  >
    <g clip-path="url(#clip0_1885_27141)">
      <rect
        class="border-element"
        x="1"
        y="1"
        width="34"
        height="34"
        rx="17"
        fill="#EBF8F1"
        stroke="#ACD1C6"
        stroke-width="2"
      />
      <circle cx="18.5263" cy="17.5264" r="8" fill="#00805A" />
      <path
        d="M17.5472 20.4847L14.5263 17.4632L15.38 16.6095L17.5472 18.7761L22.1298 14.1929L22.9847 15.0478L17.5472 20.4847Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1885_27141">
        <rect width="36" height="36" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "ValidateIcon",
};
</script>

<style scoped>
.svg-element:hover .border-element {
  stroke: #00805a;
}
</style>
