import { apiPwfb, Tiers, Adresse } from "../api-pwfb";

class ExploitationService {
  getApiBaseUrl() {
    return apiPwfb.defaults.baseURL;
  }
  getExploitations() {
    return apiPwfb.get("/account/exploitations")
      .then((response) => {
        response.data = response.data.map(t => new Tiers(t));
        return response;
      });
  }
  getPortefeuille() {
    return apiPwfb.get("/referentiel/portefeuille")
      .then((response) => {
        response.data = response.data.map(t => new Tiers(t));
        return response;
      });
  }
  getPortefeuilleVcf() {
    return apiPwfb.get("/referentiel/portefeuille/vcf");
  }
  getAdresses(codeTiers) {
    return apiPwfb.get(`/exploitation/${codeTiers}/adresses`)
      .then((response) => {
        response.data = response.data.map(a => new Adresse(a));
        return response;
      });
  }
  // DOCUMENTS
  getTotalApportParCampagne(codeTiers) {
    return apiPwfb.get(`/exploitation/${codeTiers}/total-apport-par-campagne`);
  }
  getBonsApport(codeTiers, payload) {
    return apiPwfb.post(`/collecte/bons-apport/exploitation/${codeTiers}/search`, payload);
  }
  getPieceJointeBonApport(numeroApport) {
    return apiPwfb.get(`/collecte/bon-apport/${numeroApport}/piece-jointe`);
  }
  getContratsApport(codeTiers, payload) {
    return apiPwfb.post(`/collecte/contrats-apport/exploitation/${codeTiers}/search`, payload);
  }
  getPieceJointeContratApport(idContrat) {
    return apiPwfb.get(`/collecte/contrat-apport/${idContrat}/piece-jointe`);
  }
  getCommandesApproExtranet(codeTiers, payload) {
    return apiPwfb.post(`/appro/commandes-extranet/exploitation/${codeTiers}/search`, payload);
  }
  getBonsCommandeAppro(codeTiers, payload) {
    return apiPwfb.post(`/appro/bons-commande/exploitation/${codeTiers}/search`, payload);
  }
  getPieceJointeBonCommandeAppro(numeroBon) {
    return apiPwfb.get(`/appro/bon-commande/${numeroBon}/piece-jointe`);
  }
  getBonsLivraisonAppro(codeTiers, payload) {
    return apiPwfb.post(`/appro/bons-livraison/exploitation/${codeTiers}/search`, payload);
  }
  getPieceJointeBonLivraisonAppro(numeroBon) {
    return apiPwfb.get(`/appro/bon-livraison/${numeroBon}/piece-jointe`);
  }
  getReleveCompte(codeTiers, payload) {
    return apiPwfb.post(`/administratif/releve-compte/exploitation/${codeTiers}/search`, payload);
  }
  getPieceJointeMouvementFinancier(idMouvement) {
    return apiPwfb.get(`/administratif/mouvement-financier/${idMouvement}/piece-jointe`);
  }
  getCertificats(codeTiers, payload) {
    return apiPwfb.post(`/administratif/certificats/exploitation/${codeTiers}/search`, payload);
  }
  getPieceJointeCertificat(idCertificat) {
    return apiPwfb.get(`/administratif/certificat/${idCertificat}/piece-jointe`);
  }
  createCertificat(codeTiers, formData) {
    return apiPwfb.post(
      `/administratif/certificat/exploitation/${codeTiers}/create`,
      formData,
      { headers: { "Content-Type": "multipart/form-data" } }
    );
  }
  getRecapitulatifsCapsoc(codeTiers) {
    return apiPwfb.get(`/administratif/recapitulatifs-capsoc/exploitation/${codeTiers}`);
  }
  getPieceJointeRecapitulatifCapsoc(idFichier) {
    return apiPwfb.get(`/administratif/recapitulatif-capsoc/${idFichier}/piece-jointe`);
  }
  getRecapitulatifsEgalim(codeTiers) {
    return apiPwfb.get(`/administratif/recapitulatifs-egalim/exploitation/${codeTiers}`);
  }
  getPieceJointeRecapitulatifEgalim(idFichier) {
    return apiPwfb.get(`/administratif/recapitulatif-egalim/${idFichier}/piece-jointe`);
  }
  // WIDGETS
  getEtatDeclarations(codeRecolte, codeTiers) {
    return apiPwfb.get(`/exploitation/${codeTiers}/etat-declarations/${codeRecolte}`);
  }
  getSituationFinanciere(codeTiers, limite) {
    return apiPwfb.get(`/exploitation/${codeTiers}/situation-financiere/${limite}`);
  }
  getDerniersDocuments(codeTiers, limite) {
    return apiPwfb.get(`/exploitation/${codeTiers}/derniers-documents/${limite}`);
  }
  getIndicateurs(codeTiers) {
    return apiPwfb.get(`/exploitation/${codeTiers}/indicateurs`);
  }
  getContacts(codeTiers) {
    return apiPwfb.get(`/exploitation/${codeTiers}/contacts`);
  }
  getIntervenants(codeTiers) {
    return apiPwfb.get(`/exploitation/${codeTiers}/intervenants`);
  }
  getCriteresBeef(codeTiers) {
    return apiPwfb.get(`/exploitation/${codeTiers}/criteres-beef`);
  }
  // ASSEMBLEES GENERALES
  getReponsesAgByTiers(idAssemblee, codeTiers) {
    return apiPwfb.get(`/administratif/assemblee-generale/${idAssemblee}/exploitation/${codeTiers}/reponses`);
  }
  getReponsesAg(idAssemblee, payload) {
    return apiPwfb.post(`/administratif/assemblee-generale/${idAssemblee}/reponses/search`, payload);
  }
  createBonPouvoir(formData) {
    return apiPwfb.post(
      "/administratif/assemblee-generale/bon-pouvoir/create",
      formData,
      { headers: { "Content-Type": "multipart/form-data" } }
    );
  }
  createCouponReponse(formData) {
    return apiPwfb.post(
      "/administratif/assemblee-generale/coupon-reponse/create",
      formData,
      { headers: { "Content-Type": "multipart/form-data" } }
    );
  }
  getPieceJointeReponse(idReponse) {
    return apiPwfb.get(
      `/administratif/assemblee-generale/reponse/${idReponse}/piece-jointe`);
  }
}

export default new ExploitationService();
