import BackofficeService from "../services/backoffice.service";
import ReferentielService from "../services/referentiel.service";
import UtilsService, { SortDirection } from "../services/utils.service";

/**
 * Pour chaque collection de l'objet ReferentielDto renvoyé par l'API, indique : 
 * - le nom du champ de contrôle (timestamp)
 * - le nom du champ de la collection (tableau)
 * - le tri à appliquer à la collection avant commit
 * 
 * Données nécessaires au démarrage de l'application
 */
const referentielMetadata = [
  { instant: "lastUpdateParametres", collection: "parametres", sort: UtilsService.sortByStringProperty("codeParametre"), },
  { instant: "lastUpdateEspeces", collection: "especes", sort: UtilsService.sortByStringProperty("libelle"), },
  { instant: "lastUpdateVarietes", collection: "varietes", sort: UtilsService.sortByStringProperty("libelle"), },
  { instant: "lastUpdateProduitsCereale", collection: "produitsCereale", sort: UtilsService.sortByStringProperty("libelle"), },
  { instant: "lastUpdateRecoltes", collection: "recoltes", sort: UtilsService.sortByNumberProperty("codeRecolte", SortDirection.DESC), },
  { instant: "lastUpdateMotifsAutoconso", collection: "motifsAutoconso", sort: UtilsService.sortByNumberProperty("ordre"), },
  { instant: "lastUpdateModesEnlevement", collection: "modesEnlevement", sort: UtilsService.sortByNumberProperty("ordre"), },
];

/**
 * Pour chaque collection de l'objet ReferentielDto renvoyé par l'API, indique : 
 * - le nom du champ de contrôle (timestamp)
 * - le nom du champ de la collection (tableau)
 * - le tri à appliquer à la collection avant commit
 * 
 * Données du référentiel appro uniquement
 */
const referentielApproMetadata = [
  { instant: "lastUpdateFamillesProduit", collection: "famillesProduit", sort: UtilsService.sortByStringProperty("libelle"), },
  { instant: "lastUpdateProduitsAppro", collection: "produitsAppro", sort: UtilsService.sortByStringProperty("libelle"), },
  { instant: "lastUpdateDepotsAppro", collection: "depotsAppro", sort: UtilsService.sortByStringProperty("libelle"), },
];

export const ref = {
  namespaced: true,
  state: {
    typesParcelle: [
      { code: "BIOLOGIQUE", libelle: "Biologique", },
      { code: "EN_CONVERSION", libelle: "2e année de conversion", },
      { code: "CONVENTIONNELLE", libelle: "Conventionnelle", },
    ],
    typesCulture: [
      { codes: "AB,BIO", libelle: "Biologique", codeTypeParcelle: "BIOLOGIQUE", },
      { codes: "R1,G4", libelle: "Multiplication", codeTypeParcelle: "BIOLOGIQUE", },
      { codes: "C2", libelle: "2e année de conversion", codeTypeParcelle: "EN_CONVERSION", },
      { codes: "C1,CONVENTIONNEL", libelle: "Conventionnel", codeTypeParcelle: "CONVENTIONNELLE", },
    ],
    lastUpdateParametres: new Date(JSON.parse(localStorage.getItem("ref/lastUpdateParametres"))) || null,
    parametres: JSON.parse(localStorage.getItem("ref/parametres")) || [],
    // REFERENTIEL PRODUITS CEREALE
    lastUpdateEspeces: new Date(JSON.parse(localStorage.getItem("ref/lastUpdateEspeces"))) || null,
    lastUpdateVarietes: new Date(JSON.parse(localStorage.getItem("ref/lastUpdateVarietes"))) || null,
    lastUpdateProduitsCereale: new Date(JSON.parse(localStorage.getItem("ref/lastUpdateProduitsCereale"))) || null,
    especes: JSON.parse(localStorage.getItem("ref/especes")) || [],
    varietes: JSON.parse(localStorage.getItem("ref/varietes")) || [],
    produitsCereale: JSON.parse(localStorage.getItem("ref/produitsCereale")) || [],
    // REFERENTIEL DECLARATIONS
    lastUpdateRecoltes: new Date(JSON.parse(localStorage.getItem("ref/lastUpdateRecoltes"))) || null,
    lastUpdateMotifsAutoconso: new Date(JSON.parse(localStorage.getItem("ref/lastUpdateMotifsAutoconso"))) || null,
    lastUpdateModesEnlevement: new Date(JSON.parse(localStorage.getItem("ref/lastUpdateModesEnlevement"))) || null,
    recoltes: JSON.parse(localStorage.getItem("ref/recoltes")) || [],
    motifsAutoconso: JSON.parse(localStorage.getItem("ref/motifsAutoconso")) || [],
    modesEnlevement: JSON.parse(localStorage.getItem("ref/modesEnlevement")) || [],
    // REFERENTIEL PRODUITS APPRO
    lastUpdateFamillesProduit: new Date(JSON.parse(localStorage.getItem("ref/lastUpdateFamillesProduit"))) || null,
    lastUpdateProduitsAppro: new Date(JSON.parse(localStorage.getItem("ref/lastUpdateProduitsAppro"))) || null,
    lastUpdateDepotsAppro: new Date(JSON.parse(localStorage.getItem("ref/lastUpdateDepotsAppro"))) || null,
    famillesProduit: JSON.parse(localStorage.getItem("ref/famillesProduit")) || [],
    produitsAppro: JSON.parse(localStorage.getItem("ref/produitsAppro")) || [],
    depotsAppro: JSON.parse(localStorage.getItem("ref/depotsAppro")) || [],
  },
  getters: {
    // Accesseurs standards
    typeParcelle: (state) => (codeTypeParcelle) =>
      state.typesParcelle.find((t) => t.code === codeTypeParcelle),
    typeCulture: (state) => (codeTypeCulture) =>
      state.typesCulture.find((t) => t.codes.includes(codeTypeCulture)),
    espece: (state) => (codeEspece) =>
      state.especes.find((e) => e.codeEspece === codeEspece),
    variete: (state) => (codeVariete) =>
      state.varietes.find((v) => v.codeVariete === codeVariete),
    produitCereale: (state) => (codeProduit) =>
      state.produitsCereale.find((p) => p.codeProduit === codeProduit),
    produitAppro: (state) => (codeProduit) =>
      state.produitsAppro.find((p) => p.codeProduit === codeProduit),
    motifAutoconso: (state) => (codeMotif) =>
      state.motifsAutoconso.find((m) => m.code === codeMotif),
    motifsAutoconso: (state) => (codeSociete) => {
      if (!codeSociete) {
        return [...state.motifsAutoconso];
      }
      return state.motifsAutoconso
        .map(m => {
          let p = m.params.find(p => p.codeSociete === codeSociete);
          return p ? { code: m.code, libelle: p.libelle, ordre: p.ordre, actif: m.actif, } : null;
        })
        .filter(m => m != null);
    },
    modeEnlevement: (state) => (codeMode) =>
      state.modesEnlevement.find((m) => m.code === codeMode),
    modesEnlevement: (state) => (codeSociete) => {
      if (!codeSociete) {
        return [...state.modesEnlevement];
      }
      return state.modesEnlevement
        .map(m => {
          let p = m.params.find(p => p.codeSociete === codeSociete);
          return p ? { code: m.code, libelle: p.libelle, ordre: p.ordre, actif: m.actif, } : null;
        })
        .filter(m => m != null);
    },
    depotAppro: (state) => (codeSite) =>
      state.depotsAppro.find((d) => d.codeSite === codeSite),
    depotsAppro: (state) => (codeSociete) =>
      state.depotsAppro.filter((d) => d.codeSociete === codeSociete),
    parametre: (state) => (codeParametre) =>
      state.parametres.find((p) => p.codeParametre === codeParametre),
    // Accesseurs spécifiques
    typeCultureFromProduit: (_, getters) => (codeProduit) => {
      let produit = getters.produitCereale(codeProduit);
      return getters.typeCulture(produit?.typeCulture);
    },
    /**
     * Récolte en cours de collecte ; basée sur les dates de début/fin de campagne
     */
    recolteEnCoursDeCollecte: (state) => {
      let now = new Date().toISOString().slice(0, 10);
      return state.recoltes.find((r) => {
        return r.dateDebut.localeCompare(now) <= 0 && now.localeCompare(r.dateFin) <= 0;
      })?.codeRecolte;
    },
    /**
     * Récolte en cours de saisie ; bascule au 1er novembre (après moisson tardive)
     */
    recolteEnCoursDeSaisie: (state) => {
      let now = new Date();
      let target = (now.getMonth() + 1) < 11 ? now.getFullYear() : now.getFullYear() + 1;
      return state.recoltes.find((r) => r.codeRecolte === target)?.codeRecolte ?? state.recoltes[0]?.codeRecolte;
    },
    /**
     * Token d'authentification pour l'accès aux données de Strapi
     */
    cmsApiKey: (_, getters) => getters.parametre("CMS_API_KEY")?.valeur,
    /**
     * Détermine si l'accès aux fonctionnalités du projet commande appro lot 1 est activé pour la société spécifiée
     * 
     * @param {*} codeSociete 
     */
    commandeAppro: (_, getters) => (codeSociete) =>
      getters.parametre(`COMMANDE_APPRO_${codeSociete}`)?.valeur === "true",
    /**
     * Détermine si l'accès aux échantillons moisson est activé pour la société spécifiée
     * 
     * @param {*} codeSociete 
     */
    echantillonsMoisson: (_, getters) => (codeSociete) =>
      getters.parametre(`ECHANTILLONS_MOISSON_${codeSociete}`)?.valeur === "true",
    /**
     * Détermine si l'accès à l'écran des prix fin de campagne est activé pour la société spécifiée
     * 
     * @param {*} codeSociete 
     */
    egalim: (_, getters) => (codeSociete) =>
      getters.parametre(`EGALIM_${codeSociete}`)?.valeur === "true",
    /**
     * Détermine si l'accès à l'écran du capital social est activé pour la société spécifiée
     * 
     * @param {*} codeSociete 
     */
    capitalSocial: (_, getters) => (codeSociete) =>
      getters.parametre(`CAPITAL_SOCIAL_${codeSociete}`)?.valeur === "true",
    /**
     * Date d'enlèvement pour les produits en multi (dépend de la période de moisson)
     * 
     * @param {*} codeRecolte 
     * @param {*} periodeMoisson 
     */
    dateEnlevementMulti: (_, getters) => (codeRecolte, periodeMoisson) => {
      let date = getters.parametre(`DATE_ENLEVEMENT_MULTI_${periodeMoisson}`)?.valeur;
      if (!date) {
        console.error(`La valeur du paramètre <DATE_ENLEVEMENT_MULTI_${periodeMoisson}> est nulle`);
        return null;
      }
      try {
        let [month, day] = date.split("-");
        if (parseInt(month) > 6) {
          return `${codeRecolte}-${month}-${day}`;
        } else {
          return `${parseInt(codeRecolte) + 1}-${month}-${day}`;
        }
      } catch (e) {
        console.error(`La valeur du paramètre <DATE_ENLEVEMENT_MULTI_${periodeMoisson}> est invalide`, e);
        return null;
      }
    },
    /**
     * Dates d'enlèvement autorisées pour la société et la récolte spécifiée
     * 
     * @param {*} codeSociete 
     * @param {*} codeRecolte 
     */
    datesEnlevement: (_, getters) => (codeSociete, codeRecolte) => {
      let liste = getters.parametre(`DATES_ENLEVEMENT_${codeSociete}`)?.valeur;
      if (!liste) {
        console.error(`La valeur du paramètre <DATES_ENLEVEMENT_${codeSociete}> est nulle`);
        return [];
      }
      try {
        return liste.split(";").map(d => {
          let [month, day] = d.split("-");
          if (parseInt(month) > 6) {
            return `${codeRecolte}-${month}-${day}`;
          } else {
            return `${parseInt(codeRecolte) + 1}-${month}-${day}`;
          }
        });
      } catch (e) {
        console.error(`La valeur du paramètre <DATES_ENLEVEMENT_${codeSociete}> est invalide`, e);
        return [];
      }
    },
  },
  actions: {
    async revalidate({ state, dispatch, commit }) {
      try {
        let payload = {};
        referentielMetadata.forEach(m => payload[m.instant] = state[m.instant]);
        let response = await ReferentielService.revalidate(payload);
        referentielMetadata.forEach(m => {
          let lastUpdate = Date.parse(response.data[m.instant]);
          if (state[m.instant] === null || state[m.instant] < lastUpdate) {
            commit("set", { key: m.collection, value: response.data[m.collection].sort(m.sort), });
            commit("set", { key: m.instant, value: lastUpdate, });
          }
        });
      } catch (e) {
        dispatch("clear");
      }
    },
    async revalidateAppro({ state, dispatch, commit }) {
      try {
        let payload = {};
        referentielApproMetadata.forEach(m => payload[m.instant] = state[m.instant]);
        let response = await ReferentielService.revalidateAppro(payload);
        referentielApproMetadata.forEach(m => {
          let lastUpdate = Date.parse(response.data[m.instant]);
          if (state[m.instant] === null || state[m.instant] < lastUpdate) {
            commit("set", { key: m.collection, value: response.data[m.collection].sort(m.sort), });
            commit("set", { key: m.instant, value: lastUpdate, });
          }
        });
      } catch (e) {
        dispatch("clear");
      }
    },
    async getBatchs(_, { all }) {
      let options = [];

      // Chargement des données
      let results = await Promise.all([
        BackofficeService.getAvailableJobs(),
        BackofficeService.getAvailableSteps(),
      ]);

      // Transformation en options et tri
      results.forEach((item, index) => {
        options.push({
          label: index === 0 ? "Jobs" : "Steps",
          options: item.data
            .sort((b1, b2) => (b1 || "").localeCompare(b2 || ""))
            .map((b) => {
              return { value: b, text: b };
            }),
        });
      });

      // Ajout de l'option Tous si nécessaire
      if (all === true || all === "true") {
        options = [{ value: null, text: "Tous" }, ...options];
      }

      return options;
    },
    async getBatchsStatus(_, { value, all }) {
      let status = ["COMPLETED", "STARTING", "STARTED", "STOPPING", "STOPPED", "FAILED", "ABANDONED", "UNKNOWN",];
      let options = [];

      // Ajout de l'option Tous si nécessaire
      if (all === true || all === "true") {
        options.push({ value: null, text: "Tous" });
      }

      // Ajout de l'option courante désactivée si nécessaire
      if (value && !status.find((s) => s === value)) {
        options.push({
          value: value,
          text: value,
          disabled: true,
        });
      }

      // Ajout des statuts pris en charge
      status.forEach((s) => {
        options.push({ value: s, text: s });
      });

      return options;
    },
    clear({ commit }) {
      referentielMetadata.forEach(m => {
        commit("set", { key: m.collection, value: [], });
        commit("set", { key: m.instant, value: null, });
      });
      referentielApproMetadata.forEach(m => {
        commit("set", { key: m.collection, value: [], });
        commit("set", { key: m.instant, value: null, });
      });
    },
  },
  mutations: {
    set(state, { key, value, }) {
      localStorage.setItem(`ref/${key}`, JSON.stringify(value));
      state[key] = value;
    },
  },
};
