import jwt_decode from "jwt-decode";
import AuthService from "../services/auth.service";
import AccountService from "../services/account.service";
import UtilsService from "../services/utils.service";

export const auth = {
  namespaced: true,
  state: {
    loggedIn: JSON.parse(localStorage.getItem("auth/loggedIn")) || false,
    user: JSON.parse(localStorage.getItem("auth/user")) || null,
    accessToken: JSON.parse(localStorage.getItem("auth/accessToken")) || null,
    refreshToken: JSON.parse(localStorage.getItem("auth/refreshToken")) || null,
    error: null,
  },
  getters: {
    loggedIn: (state) =>
      state.loggedIn === true,
    groupes: (state) =>
      state.user?.groupes.map((g) => g.codeGroupe) || [],
    isRoot: (state, getters) =>
      getters.groupes.includes("ROOT"),
    isSalarie: (state, getters) =>
      getters.groupes.includes("SALARIE"),
    isCommunication: (state, getters) =>
      getters.groupes.includes("COMMUNICATION"),
    isTechnicien: (state, getters) =>
      getters.groupes.includes("TECHNICIEN"),
    isLogistique: (state, getters) =>
      getters.groupes.includes("LOGISTIQUE"),
    isAdherent: (state, getters) =>
      getters.groupes.includes("ADHERENT"),
    expirationSession: (state) =>
      jwt_decode(state.refreshToken)?.exp * 1000,
  },
  actions: {
    async login({ commit, dispatch }, payload) {
      try {
        commit("setError", null);
        let response = await AuthService.login(payload);
        commit("setAccessToken", response.data.accessToken);
        commit("setRefreshToken", response.data.refreshToken);
        await dispatch("loadUserAccount");
        await dispatch("onLogin", null, { root: true });
        commit("setLoggedIn", true);
      } catch (e) {
        await dispatch("logout");
        commit("setError", UtilsService.handleError(e));
        throw e;
      }
    },
    async mount({ commit, dispatch }) {
      try {
        commit("setError", null);
        await dispatch("loadUserAccount");
        await dispatch("onMount", null, { root: true });
      } catch (e) {
        await dispatch("logout");
        commit("setError", UtilsService.handleError(e));
        throw e;
      }
    },
    async loadUserAccount({ commit }) {
      let response = await AccountService.getSelfAccount();
      commit("setUser", response.data);
    },
    async refresh({ commit, dispatch }) {
      try {
        commit("setError", null);
        commit("setAccessToken", null);
        let response = await AuthService.refresh();
        commit("setAccessToken", response.data.accessToken);
      } catch (e) {
        await dispatch("logout");
        commit("setError", UtilsService.handleError(e));
        throw e;
      }
    },
    async logout({ commit, dispatch }) {
      commit("setError", null);
      commit("setLoggedIn", false);
      commit("setUser", null);
      commit("setAccessToken", null);
      commit("setRefreshToken", null);
      await dispatch("onLogout", null, { root: true });
    },
  },
  mutations: {
    setLoggedIn(state, loggedIn) {
      localStorage.setItem("auth/loggedIn", JSON.stringify(loggedIn));
      state.loggedIn = loggedIn;
    },
    setUser(state, user) {
      localStorage.setItem("auth/user", JSON.stringify(user));
      state.user = user;
    },
    setAccessToken(state, accessToken) {
      localStorage.setItem("auth/accessToken", JSON.stringify(accessToken));
      state.accessToken = accessToken;
    },
    setRefreshToken(state, refreshToken) {
      localStorage.setItem("auth/refreshToken", JSON.stringify(refreshToken));
      state.refreshToken = refreshToken;
    },
    setError(state, error) {
      state.error = error;
    }
  },
};
