<template>
  <svg
    class="font-icon"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="18" height="18" rx="2" />
    <path
      d="M4.5 10V13"
      stroke="#054643"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M9 5V13"
      stroke="#054643"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M13 7V13"
      stroke="#054643"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  name: "CollecteMenuIcon",
};
</script>
