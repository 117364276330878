import axios from "axios";

let apiStrapi;

const setup = (config, store) => {
  // Creation de l'instance Axios
  apiStrapi = axios.create({
    baseURL: config.CMS_API_BASE_URL,
    headers: {
      "Content-Type": "application/json",
    },
  });

  // Ajout automatique de la cle d'API a toute requete sortante
  apiStrapi.interceptors.request.use(
    (config) => {
      config.headers["Authorization"] = `Bearer ${store.getters["ref/cmsApiKey"]}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export default setup;
export { apiStrapi };
