<template>
  <b-container fluid class="d-block d-md-none menu-mobile">
    <b-row align-v="center" style="height: 60px">
      <b-col cols="auto">
        <strong>
          <span class="h1">
            <b-icon-list v-b-toggle.sidebar-mobile role="button"></b-icon-list>
          </span>
        </strong>
      </b-col>
      <b-col class="text-center">
        <b-link class="text-light" :to="{ name: 'exploitations' }">
          <strong>
            {{ exploitationCourante.raisonSociale }}
          </strong>
        </b-link>
      </b-col>
      <b-col cols="auto">
        <b-link :to="{ name: 'compte' }">
          <span class="h2">
            <AccountMenuIcon></AccountMenuIcon>
          </span>
        </b-link>
      </b-col>
    </b-row>
    <b-sidebar
      id="sidebar-mobile"
      :title="societe.libelle"
      text-variant="light"
      bg-variant="primary"
      shadow
    >
      <b-navbar class="d-flex flex-column" style="width: 100%">
        <b-nav vertical>
          <b-row
            v-if="$store.state.environment !== 'prod'"
            class="m-0"
            style="background-color: orange"
          >
            <b-col>
              <b-nav-text class="w-100 text-center">
                <strong style="color: blue">
                  ENVIRONNEMENT DE
                  {{ $store.state.environment.toUpperCase() }}
                </strong>
              </b-nav-text>
            </b-col>
          </b-row>
          <b-row class="m-0">
            <b-col>
              <b-nav-text class="w-100 text-center">
                <ExploitationMenuIcon></ExploitationMenuIcon>
                {{ exploitationCourante.raisonSociale }}
              </b-nav-text>
            </b-col>
          </b-row>
        </b-nav>
        <b-nav vertical>
          <b-nav-item :to="{ name: 'accueil' }">
            <AccueilMenuIcon></AccueilMenuIcon>
            Accueil
          </b-nav-item>
          <b-nav-item :to="{ name: 'exploitations' }">
            <ExploitationMenuIcon></ExploitationMenuIcon>
            Mes exploitations
          </b-nav-item>
          <!-- Menu coopérative -->
          <CollapsibleMenuComponent
            v-if="societe"
            icon="CooperativeMenuIcon"
            :title="societe.libelle"
            ref="menuCooperative"
            @show="closeMenusExcept('menuCooperative')"
          >
            <b-nav-item
              v-for="page in getPages(societe.code)"
              :key="page.id"
              :to="{ name: 'page', params: { id: page.id } }"
            >
              {{ page.attributes.titre }}
            </b-nav-item>
          </CollapsibleMenuComponent>
          <!-- Menu campagnes -->
          <CollapsibleMenuComponent
            icon="DeclarationsMenuIcon"
            title="Déclarations"
            ref="menuDeclarations"
            @show="closeMenusExcept('menuDeclarations')"
          >
            <b-nav-item
              v-for="recolte in recoltes"
              :key="recolte"
              :to="{ name: 'recolte', params: { codeRecolte: recolte } }"
            >
              Campagne {{ recolte }}
            </b-nav-item>
          </CollapsibleMenuComponent>
          <!-- Menu appro -->
          <CollapsibleMenuComponent
            icon="ApproMenuIcon"
            title="Appro"
            ref="menuAppro"
            @show="closeMenusExcept('menuAppro')"
          >
            <b-nav-item :to="{ name: 'catalogues' }">Catalogues</b-nav-item>
            <b-nav-item
              v-if="accesCommandeAppro"
              :to="{ name: 'commandes-appro-extranet' }"
            >
              Commandes extranet
            </b-nav-item>
            <b-nav-item
              v-if="accesCommandeAppro"
              :to="{ name: 'bons-commande-appro' }"
            >
              Bons de commande
            </b-nav-item>
            <b-nav-item :to="{ name: 'bons-livraison-appro' }">
              Bons de livraison
            </b-nav-item>
          </CollapsibleMenuComponent>
          <!-- Menu collecte -->
          <CollapsibleMenuComponent
            icon="CollecteMenuIcon"
            title="Collecte"
            ref="menuCollecte"
            @show="closeMenusExcept('menuCollecte')"
          >
            <b-nav-item :to="{ name: 'reglements-campagne' }">
              R&egrave;glements de campagne
            </b-nav-item>
            <b-nav-item
              v-if="accesEchantillonsMoisson"
              :to="{ name: 'analyses-moisson' }"
            >
              Echantillons moisson
            </b-nav-item>
            <b-nav-item :to="{ name: 'bons-apport' }">Bons d'apport</b-nav-item>
            <b-nav-item :to="{ name: 'contrats-apport' }">
              Suivi de la collecte
            </b-nav-item>
            <b-nav-item v-if="accesEgalim" :to="{ name: 'prix-fin-campagne' }">
              Prix fin de campagne
            </b-nav-item>
          </CollapsibleMenuComponent>
          <!-- Menu administratif -->
          <CollapsibleMenuComponent
            icon="AdministratifMenuIcon"
            title="Administratif"
            ref="menuAdministratif"
            @show="closeMenusExcept('menuAdministratif')"
          >
            <b-nav-item :to="{ name: 'certificats' }">
              Certificats Bio
            </b-nav-item>
            <b-nav-item :to="{ name: 'releve-compte' }">
              Relev&eacute; de compte
            </b-nav-item>
            <b-nav-item
              v-if="accesCapitalSocial"
              :to="{ name: 'situation-capsoc' }"
            >
              Capital social
            </b-nav-item>
          </CollapsibleMenuComponent>
          <!-- Menu communication -->
          <CollapsibleMenuComponent
            v-show="isSalarie"
            icon="BIconMegaphoneFill"
            title="Communication"
            ref="menuCommunication"
            @show="closeMenusExcept('menuCommunication')"
          >
            <b-nav-item :to="{ name: 'com-assistant' }">
              Nouvel envoi
            </b-nav-item>
            <b-nav-item :to="{ name: 'com-templates' }">Templates</b-nav-item>
            <b-nav-item :to="{ name: 'com-historique' }">Historique</b-nav-item>
          </CollapsibleMenuComponent>
          <section v-if="isSalarie">
            <b-nav-item :to="{ name: 'referentiel' }">
              <b-icon-ui-checks></b-icon-ui-checks>
              R&eacute;f&eacute;rentiel
            </b-nav-item>
            <b-nav-item :to="{ name: 'backoffice' }">
              <b-icon-tools></b-icon-tools>
              Backoffice
            </b-nav-item>
          </section>
        </b-nav>
        <div class="w-100 mt-1 mb-1" style="border-top: 1px solid white"></div>
        <b-nav small vertical>
          <b-nav-item :to="{ name: 'faq' }">Foire aux questions</b-nav-item>
          <b-nav-item :to="{ name: 'changelog' }">
            Historique des changements
          </b-nav-item>
          <b-nav-item :to="{ name: 'ecoconception' }">
            Conception/r&eacute;alisation
          </b-nav-item>
          <b-nav-item :to="{ name: 'mentions' }">
            Mentions l&eacute;gales
          </b-nav-item>
          <b-nav-text class="w-100 text-center">
            <em>
              &copy; 2022 Union Fermes Bio - Contact :
              <b-link href="mailto:idees_extranet@fermesbio.fr">
                idees_extranet@fermesbio.fr
              </b-link>
            </em>
          </b-nav-text>
        </b-nav>
      </b-navbar>
    </b-sidebar>
  </b-container>
</template>

<script>
import AccountMenuIcon from "../icons/AccountMenuIcon.vue";
import CollapsibleMenuComponent from "./CollapsibleMenuComponent.vue";
import UtilsService from "../../services/utils.service";
export default {
  name: "MenuMobileComponent",
  components: { AccountMenuIcon, CollapsibleMenuComponent },
  data() {
    return {
      // Nom des routes gérées par chaque menu
      collapses: this.$store.state.structureMenu,
    };
  },
  computed: {
    recoltes() {
      return this.$store.state.ref.recoltes.map((r) => r.codeRecolte);
    },
    exploitationCourante() {
      return this.$store.getters["expl/adherentCourant"];
    },
    societe() {
      return this.$store.getters["societe"](
        this.exploitationCourante.codeSociete
      );
    },
    logoSociete() {
      return this.societe.logoBlanc ?? this.societe.logo;
    },
    isSalarie() {
      return this.$store.getters["auth/isSalarie"];
    },
    accesCommandeAppro() {
      return this.$store.getters["ref/commandeAppro"](
        this.exploitationCourante.codeSociete
      );
    },
    accesEchantillonsMoisson() {
      return this.$store.getters["ref/echantillonsMoisson"](
        this.exploitationCourante.codeSociete
      );
    },
    accesEgalim() {
      return (
        this.exploitationCourante.codeTypeTiers === "A" &&
        this.$store.getters["ref/egalim"](this.exploitationCourante.codeSociete)
      );
    },
    accesCapitalSocial() {
      return (
        this.exploitationCourante.codeTypeTiers === "A" &&
        this.$store.getters["ref/capitalSocial"](
          this.exploitationCourante.codeSociete
        )
      );
    },
  },
  mounted() {
    this.openMenuActif();
    this.$watch(
      () => this.$route.path,
      () => this.openMenuActif()
    );
    this.$watch(
      () => this.$route.params,
      () => this.openMenuActif()
    );
  },
  methods: {
    getPages(codeSociete) {
      return this.$store.getters["strapi/pages"](codeSociete).sort(
        UtilsService.sortByNumberProperty("attributes.ordre")
      );
    },
    openMenuActif() {
      for (const name in this.collapses) {
        let activeMenu = this.collapses[name].includes(this.$route.name);
        if (activeMenu) {
          this.$refs[name].show();
        } else {
          this.$refs[name].hide();
        }
      }
    },
    closeMenusExcept(except) {
      for (const name in this.collapses) {
        if (name !== except) {
          this.$refs[name].hide();
        }
      }
    },
  },
};
</script>
