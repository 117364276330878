<template>
  <svg class="font-icon" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.7541 4.97375L15.4527 2.49074C13.8822 2.58427 11.9829 2.95311 10.1606 3.4803C8.9223 3.83855 7.54325 3.7845 6.26115 3.54795C5.85735 3.47345 5.45955 3.38015 5.0744 3.27405L6.2281 4.9725C8.58385 5.8421 11.398 5.8425 13.7541 4.97375ZM5.64075 5.81825C8.3511 6.89425 11.6375 6.8939 14.3475 5.81725C15.4493 6.96035 16.5875 8.38285 17.4644 9.8708C18.3812 11.4263 18.9599 12.9647 18.998 14.2861C19.034 15.5367 18.5946 16.614 17.357 17.4412C16.0455 18.3178 13.7601 18.9622 10.012 18.9984C6.26285 19.0347 3.97395 18.434 2.65866 17.5836C1.42087 16.7834 0.973 15.7176 1.00122 14.4601C1.03106 13.1307 1.60309 11.5701 2.51792 9.98175C3.39125 8.46535 4.5297 7.00515 5.64075 5.81825ZM4.831 2.15974C5.34465 2.32143 5.8888 2.46235 6.4426 2.56453C7.64145 2.78573 8.84935 2.81863 9.8827 2.51969C11.1959 2.13977 12.5746 1.83192 13.8665 1.64955C12.7641 1.28662 11.4313 1 10.0216 1C7.98715 1 6.0984 1.59697 4.831 2.15974ZM3.87345 1.50615C5.2259 0.82873 7.51245 0 10.0216 0C12.4843 0 14.7153 0.798315 16.0619 1.46836C16.0846 1.47962 16.1069 1.49083 16.129 1.50201C16.4919 1.68539 16.7868 1.85785 17 2L14.9478 5C19.6787 9.83635 25.325 19.8505 10.0216 19.9984C-5.28178 20.1463 0.26587 10.0213 5.0379 5L3 2C3.1426 1.90628 3.321 1.79939 3.5311 1.68487C3.6373 1.62701 3.75155 1.56719 3.87345 1.50615Z"
    />
  </svg>
</template>

<script>
export default {
  name: "AutoconsoTabIcon",
};
</script>
