<template>
  <svg class="font-icon" viewBox="0 0 18 19" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.455702 2.07321C0.204154 2.07321 0 1.86681 0 1.6125V0.460714C0 0.2064 0.204154 0 0.455702 0H1.59496C1.8465 0 2.05066 0.2064 2.05066 0.460714C2.05066 0.715029 1.8465 0.921429 1.59496 0.921429H0.911403V1.6125C0.911403 1.86681 0.707249 2.07321 0.455702 2.07321Z"
    />
    <path
      d="M0.455702 11.2875C0.204154 11.2875 0 11.0811 0 10.8268V8.52321C0 8.2689 0.204154 8.0625 0.455702 8.0625C0.707249 8.0625 0.911403 8.2689 0.911403 8.52321V10.8268C0.911403 11.0811 0.707249 11.2875 0.455702 11.2875Z"
    />
    <path
      d="M0.455702 6.68057C0.204154 6.68057 0 6.47417 0 6.21985V3.91628C0 3.66197 0.204154 3.45557 0.455702 3.45557C0.707249 3.45557 0.911403 3.66197 0.911403 3.91628V6.21985C0.911403 6.47417 0.707249 6.68057 0.455702 6.68057Z"
    />
    <path
      d="M1.40675 15.1459C1.32085 15.1459 1.23381 15.1215 1.15657 15.0701L0.205066 14.4369C0.0770135 14.3516 0 14.207 0 14.052V12.9002C0 12.6459 0.204154 12.4395 0.455702 12.4395C0.707249 12.4395 0.911404 12.6459 0.911404 12.9002V13.8036L1.65784 14.3003C1.86815 14.4401 1.92625 14.726 1.78795 14.9386C1.70022 15.0731 1.55508 15.1459 1.40675 15.1459Z"
    />
    <path
      d="M14.1244 18.4288H11.8429C11.5914 18.4288 11.3872 18.2224 11.3872 17.968C11.3872 17.7137 11.5914 17.5073 11.8429 17.5073H14.1246C14.3762 17.5073 14.5803 17.7137 14.5803 17.968C14.5803 18.2224 14.3762 18.4288 14.1244 18.4288Z"
    />
    <path
      d="M9.56138 18.4288H7.27968C7.02813 18.4288 6.82397 18.2224 6.82397 17.968C6.82397 17.7137 7.02813 17.5073 7.27968 17.5073H9.56138C9.81292 17.5073 10.0171 17.7137 10.0171 17.968C10.0171 18.2224 9.81292 18.4288 9.56138 18.4288Z"
    />
    <path
      d="M5.21869 17.6829C5.13279 17.6829 5.04575 17.6584 4.96851 17.6068L3.06299 16.3383C2.85292 16.1984 2.79459 15.9126 2.93312 15.7002C3.07142 15.4876 3.35419 15.4286 3.56449 15.5689L5.47001 16.8375C5.68009 16.9773 5.73842 17.2631 5.59989 17.4755C5.51216 17.6098 5.36702 17.6829 5.21869 17.6829Z"
    />
    <path
      d="M17.5444 18.4286H16.4052C16.1536 18.4286 15.9495 18.2222 15.9495 17.9679C15.9495 17.7136 16.1536 17.5072 16.4052 17.5072H17.0582L17.0158 16.8484C16.9996 16.5945 17.1901 16.3752 17.4412 16.3589C17.6898 16.3427 17.9092 16.5351 17.9254 16.7889L17.999 17.9382C18.007 18.0653 17.9628 18.19 17.8766 18.283C17.7907 18.3759 17.6704 18.4286 17.5444 18.4286Z"
    />
    <path
      d="M17.35 15.4057C17.1117 15.4057 16.9114 15.2187 16.8957 14.9749L16.7756 13.1014C16.7594 12.8474 16.9499 12.6281 17.2008 12.6117C17.4555 12.5963 17.6688 12.7879 17.6852 13.0416L17.8053 14.9151C17.8215 15.1691 17.631 15.3884 17.3801 15.4048C17.3699 15.4055 17.3601 15.4057 17.35 15.4057Z"
    />
    <path
      d="M17.1099 11.6587C16.8716 11.6587 16.6713 11.4717 16.6556 11.2279L16.6032 10.4104L15.8191 10.2035C15.5758 10.1393 15.43 9.88749 15.4933 9.64123C15.5569 9.39544 15.8048 9.24778 16.0495 9.31182L17.1518 9.60299C17.3416 9.65344 17.4786 9.82091 17.4913 10.019L17.5652 11.1683C17.5813 11.4224 17.3911 11.6414 17.14 11.658C17.13 11.6585 17.1199 11.6587 17.1099 11.6587Z"
    />
    <path
      d="M14.6095 9.86857C14.5715 9.86857 14.5327 9.86373 14.494 9.85359L13.1695 9.50368C12.9261 9.43941 12.7803 9.18763 12.8437 8.94138C12.9072 8.69513 13.1549 8.54701 13.3998 8.61197L14.7243 8.96188C14.9677 9.02615 15.1135 9.27793 15.0502 9.52418C14.9966 9.73104 14.8121 9.86857 14.6095 9.86857Z"
    />
    <path
      d="M11.9604 9.16898C11.9223 9.16898 11.8836 9.16415 11.8449 9.15401L10.7425 8.86284C10.5953 8.82391 10.4766 8.71311 10.427 8.56729C10.3773 8.42147 10.403 8.26045 10.4953 8.1379L11.1855 7.22154C11.3379 7.01952 11.6236 6.9799 11.8241 7.13424C12.0244 7.28835 12.0632 7.57745 11.9105 7.77993L11.6348 8.14597L12.0755 8.2623C12.3188 8.32657 12.4646 8.57835 12.4013 8.82437C12.3475 9.03192 12.163 9.16898 11.9604 9.16898Z"
    />
    <path
      d="M13.0857 5.9202C12.9896 5.9202 12.8925 5.88956 12.81 5.82598C12.6098 5.67187 12.571 5.38277 12.7237 5.18029L14.2614 3.13909C14.4139 2.93684 14.6994 2.89745 14.9001 3.05179C15.1004 3.2059 15.1391 3.495 14.9865 3.69748L13.4487 5.73867C13.3589 5.85777 13.2231 5.9202 13.0857 5.9202Z"
    />
    <path
      d="M16.1657 1.83779C16.07 1.83779 15.9734 1.80738 15.8911 1.74449C15.6904 1.59085 15.6507 1.30198 15.8027 1.09903L15.9355 0.921429H15.7218C15.4703 0.921429 15.2661 0.715029 15.2661 0.460714C15.2661 0.2064 15.4703 0 15.7218 0H16.852C17.0249 0 17.183 0.0990536 17.26 0.255466C17.337 0.411879 17.3197 0.599159 17.2154 0.738755L16.5295 1.65512C16.4398 1.77467 16.3037 1.83779 16.1657 1.83779Z"
    />
    <path
      d="M13.6712 0.921429H11.6205C11.3689 0.921429 11.1648 0.715029 11.1648 0.460714C11.1648 0.2064 11.3689 0 11.6205 0H13.6712C13.9227 0 14.1269 0.2064 14.1269 0.460714C14.1269 0.715029 13.9227 0.921429 13.6712 0.921429Z"
    />
    <path
      d="M9.56984 0.921429H7.51918C7.26763 0.921429 7.06348 0.715029 7.06348 0.460714C7.06348 0.2064 7.26763 0 7.51918 0H9.56984C9.82138 0 10.0255 0.2064 10.0255 0.460714C10.0255 0.715029 9.82138 0.921429 9.56984 0.921429Z"
    />
    <path
      d="M5.46852 0.921429H3.41786C3.16631 0.921429 2.96216 0.715029 2.96216 0.460714C2.96216 0.2064 3.16631 0 3.41786 0H5.46852C5.72007 0 5.92422 0.2064 5.92422 0.460714C5.92422 0.715029 5.72007 0.921429 5.46852 0.921429Z"
    />
  </svg>
</template>

<script>
export default {
  name: "ParcellesTabIcon",
};
</script>
