<template>
  <svg
    class="font-icon"
    width="19"
    height="18"
    viewBox="0 0 19 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1902_5139)">
      <path d="M6.82005 1.87988H4.18005V11.5299H6.82005V1.87988Z" />
      <path d="M1.75997 1.87988H0.589966V11.5699H1.75997V1.87988Z" />
      <path d="M0.589966 17.42H1.16997V11.56L0.589966 11.54V17.42Z" />
      <path d="M6.79997 17.42H6.21997V11.52L6.81997 11.53L6.79997 17.42Z" />
      <path d="M3.69998 8.72998H2.22998V11.55H3.69998V8.72998Z" />
      <path d="M3.69998 5.31006H2.22998V8.14006H3.69998V5.31006Z" />
      <path d="M3.69998 1.89014H2.22998V4.76014H3.69998V1.89014Z" />
      <path
        d="M6.81004 1.4C6.81004 0.63 5.41004 0 3.67004 0C1.93004 0 0.540039 0.63 0.540039 1.4"
      />
      <path
        d="M0.619995 11.9402L3.12 14.8402H4.13L6.8 11.9102L0.619995 11.9402Z"
      />
      <path
        d="M0.25 17.6699H17.77"
        stroke="white"
        stroke-width="0.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M17.72 17.2899V12.3499L12.93 8.62988L8.09998 12.3899V17.2999H10.5V14.1499C10.5 14.0624 10.5347 13.9784 10.5966 13.9165C10.6585 13.8547 10.7425 13.8199 10.83 13.8199H15.09C15.1775 13.8199 15.2614 13.8547 15.3233 13.9165C15.3852 13.9784 15.42 14.0624 15.42 14.1499V17.3199L17.72 17.2899Z"
        stroke="white"
        stroke-width="0.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1902_5139">
        <rect width="18.02" height="17.92" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "CooperativeMenuIcon",
};
</script>
