import { apiPwfb } from "../api-pwfb";

class BackofficeService {
  getLogsMails(payload) {
    return apiPwfb.post("/backoffice/mails", payload);
  }
  getStatsUtilisateur(agregat, dateDebut, dateFin) {
    if (agregat === "UTILISATEUR") {
      return this.getStatsParUtilisateur(dateDebut, dateFin);
    } else if (agregat === "TYPE-UTILISATEUR") {
      return this.getStatsParTypeUtilisateur(dateDebut, dateFin);
    } else if (agregat === "GROUPE") {
      return this.getStatsParGroupe(dateDebut, dateFin);
    } else {
      return Promise.reject(false);
    }
  }
  getStatsParUtilisateur(dateDebut, dateFin) {
    let params = dateDebut ? `dateDebut=${dateDebut}&` : "";
    params += dateFin ? `dateFin=${dateFin}&` : "";
    return apiPwfb.get(`/backoffice/stats/utilisateur?${params}`);
  }
  getStatsParTypeUtilisateur(dateDebut, dateFin) {
    let params = dateDebut ? `dateDebut=${dateDebut}&` : "";
    params += dateFin ? `dateFin=${dateFin}&` : "";
    return apiPwfb.get(`/backoffice/stats/type-utilisateur?${params}`);
  }
  getStatsParGroupe(dateDebut, dateFin) {
    let params = dateDebut ? `dateDebut=${dateDebut}&` : "";
    params += dateFin ? `dateFin=${dateFin}&` : "";
    return apiPwfb.get(`/backoffice/stats/groupe?${params}`);
  }
  getStatsNavigationParPage(dateDebut, dateFin, email) {
    let params = dateDebut ? `dateDebut=${dateDebut}&` : "";
    params += dateFin ? `dateFin=${dateFin}&` : "";
    params += email && email !== "" ? `email=${email}&` : "";
    return apiPwfb.get(`/backoffice/stats/page?${params}`);
  }
  logNavigation(payload) {
    return apiPwfb.post("/backoffice/stats/navigation", payload);
  }
  getAvailableJobs() {
    return apiPwfb.get("/batch/jobs");
  }
  executeJob(jobName) {
    return apiPwfb.get(`/batch/job/${jobName}/execute`);
  }
  getAvailableSteps() {
    return apiPwfb.get("/batch/steps");
  }
  executeStep(stepName) {
    return apiPwfb.get(`/batch/step/${stepName}/execute`);
  }
  getJobsSessions(payload) {
    return apiPwfb.post("/batch/job-sessions/search", payload);
  }
  getJobSessionStatus(jobId) {
    return apiPwfb.get(`/batch/job-session/${jobId}/status`);
  }
  getJobSessionSteps(jobId) {
    return apiPwfb.get(`/batch/job-session/${jobId}/steps`);
  }
}

export default new BackofficeService();
