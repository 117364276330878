<template>
  <b-container fluid class="d-block d-md-none footer-mobile">
    <b-row no-gutters align-v="center" style="height: 60px">
      <b-nav fill align="center">
        <b-nav-item :to="{ name: 'accueil' }">
          <div class="text-center">
            <AccueilMenuIcon></AccueilMenuIcon>
            <br />
            Accueil
          </div>
        </b-nav-item>
        <b-nav-item :to="{ name: 'catalogues' }">
          <div class="text-center">
            <ApproMenuIcon></ApproMenuIcon>
            <br />
            Catalogues
          </div>
        </b-nav-item>
        <b-nav-item
          :to="{
            name: 'recolte',
            params: { codeRecolte: recolteEnCoursDeSaisie },
          }"
        >
          <div class="text-center">
            <DeclarationsMenuIcon></DeclarationsMenuIcon>
            <br />
            Campagne {{ recolteEnCoursDeSaisie }}
          </div>
        </b-nav-item>
        <b-nav-item :to="{ name: 'releve-compte' }">
          <div class="text-center">
            <AdministratifMenuIcon></AdministratifMenuIcon>
            <br />
            Factures
          </div>
        </b-nav-item>
      </b-nav>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "FooterMobileComponent",
  computed: {
    recolteEnCoursDeSaisie() {
      return this.$store.getters["ref/recolteEnCoursDeSaisie"];
    },
  },
};
</script>
