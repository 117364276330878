<template>
  <svg
    class="font-icon"
    width="18"
    height="21"
    viewBox="0 0 18 21"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.02576 16.4766L8.02987 16.4775C8.02704 16.477 8.02573 16.4766 8.02576 16.4766Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 2.07692C0 0.92987 0.929866 0 2.07692 0H13.4406L18 4.55939V18.6923C18 19.8393 17.0701 20.7692 15.9231 20.7692H2.07692C0.929866 20.7692 0 19.8393 0 18.6923V2.07692ZM8.30769 5.53846H4.15385V6.92308H8.30769V5.53846ZM13.8462 9.69231H4.15385V11.0769H13.8462V9.69231ZM7.34497 15.1031C6.78579 15.2533 6.3491 15.6804 6.19521 16.142L4.88164 15.7042C5.1893 14.7812 5.99876 14.0311 6.98573 13.766C7.96482 13.5029 9.08111 13.7233 10.0476 14.6124C10.338 14.527 10.6256 14.4789 10.9101 14.4637C11.6559 14.4237 12.3262 14.6118 12.9009 14.8512C13.3229 15.0271 13.7321 15.2495 14.0761 15.4364C14.1859 15.4961 14.2894 15.5523 14.3842 15.6021C14.8319 15.8371 15.0782 15.9231 15.2308 15.9231V17.3077C14.6909 17.3077 14.1584 17.0474 13.7406 16.8281C13.6117 16.7604 13.487 16.6926 13.3639 16.6258C13.0353 16.4473 12.7185 16.2753 12.3683 16.1294C11.9045 15.9361 11.4498 15.8213 10.9841 15.8464C10.9164 15.85 10.8473 15.8566 10.7768 15.8667L10.7784 15.8851C10.8154 16.3349 10.6298 16.7047 10.3908 16.9703C9.94965 17.4604 9.23104 17.7175 8.69112 17.8157C8.40986 17.8668 8.10734 17.8883 7.84098 17.8494C7.71059 17.8302 7.54247 17.7902 7.38409 17.6959C7.21567 17.5957 7.01608 17.4039 6.96492 17.097C6.91733 16.8114 7.02479 16.5761 7.1187 16.4314C7.21556 16.2821 7.34599 16.1531 7.48076 16.0416C7.74303 15.8244 8.12512 15.5895 8.63564 15.3313L8.67286 15.3043C8.19062 15.0225 7.72751 15.0004 7.34497 15.1031Z"
    />
  </svg>
</template>

<script>
export default {
  name: "AdministratifMenuIcon",
};
</script>
