<template>
  <svg
    class="font-icon svg-element"
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    @click="$emit('click')"
  >
    <g clip-path="url(#clip0_1885_27142)">
      <rect
        class="border-element"
        x="1"
        y="1"
        width="34"
        height="34"
        rx="17"
        fill="#FEF3EE"
        stroke="#F8D4D5"
        stroke-width="2"
      />
      <circle cx="18" cy="18" r="8" fill="#B94245" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.7887 21.2704C14.626 21.1077 14.626 20.8438 14.7887 20.6811L20.6813 14.7885C20.844 14.6258 21.1078 14.6258 21.2705 14.7885C21.4332 14.9512 21.4332 15.2151 21.2705 15.3778L15.378 21.2704C15.2152 21.4331 14.9514 21.4331 14.7887 21.2704Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.7887 14.7885C14.9514 14.6258 15.2152 14.6258 15.378 14.7885L21.2705 20.6811C21.4332 20.8438 21.4332 21.1077 21.2705 21.2704C21.1078 21.4331 20.844 21.4331 20.6813 21.2704L14.7887 15.3778C14.626 15.2151 14.626 14.9512 14.7887 14.7885Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1885_27142">
        <rect width="36" height="36" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "CancelIcon",
};
</script>

<style scoped>
.svg-element:hover .border-element {
  stroke: #b94245;
}
</style>
