import { defaultPageables, operationsByCode, operationsByType, sharedFilters, filtersMetadata, fields, } from "./tables.defaults";

export const tab = {
  namespaced: true,
  state: {
    // Taille des pages (commune à toute l'application)
    perPage: localStorage.getItem("tabv2/perPage")
      ? JSON.parse(localStorage.getItem("tabv2/perPage"))
      : 10,
    // Pageables modifiés par l'utilisateur
    pageables: localStorage.getItem("tabv2/pageables")
      ? new Map(Object.entries(JSON.parse(localStorage.getItem("tabv2/pageables"))))
      : new Map(),
    // Filtres communs à toute l'application
    sharedFilters: localStorage.getItem("tabv2/sharedFilters")
      ? new Map(Object.entries(JSON.parse(localStorage.getItem("tabv2/sharedFilters"))))
      : sharedFilters,
    // Filtres modifiés par l'utilisateur
    filters: localStorage.getItem("tabv2/filters")
      ? new Map(Object.entries(JSON.parse(localStorage.getItem("tabv2/filters"))))
      : new Map(),
  },
  getters: {
    /**
     * Renvoie la définition des champs correspondant à la clé spécifiée
     * 
     * @param {*} key 
     */
    fields: () => (key) => fields.get(key) ?? [],
    /**
     * Renvoie le pageable correspondant à la clé spécifiée
     * 
     * @param {*} key 
     */
    pageable: (state) => (key) => {
      let pageable = state.pageables.get(key) ?? defaultPageables.get(key);
      return { ...pageable, perPage: state.perPage, };
    },
    /**
     * Renvoie le nombre de tris actifs correspondant à la clé spécifiée
     * 
     * @param {*} key 
     */
    activeSorts: (state, getters) => (key) =>
      getters.pageable(key).sortBy.length,
    /**
     * Renvoie la définition des filtres correspondant à la clé spécifiée
     * 
     * @param {*} key 
     */
    filtersMetadata: () => (key) => filtersMetadata.get(key) ?? [],
    /**
     * Renvoie l'opération correspondant au code spécifié
     * 
     * @param {*} code 
     */
    operation: () => (code) => operationsByCode.get(code),
    /**
     * Renvoie la liste des opérations prises en charge par le type spécifié
     * 
     * @param {*} type 
     */
    operationsFromType: () => (type) => operationsByType.get(type) ?? [],
    /**
     * Renvoie le filtre correspondant à la propriété partagée spécifiée
     * 
     * @param {*} key 
     */
    sharedFilter: (state) => (property) => state.sharedFilters.get(property),
    /**
     * Renvoie un Array de tous les filtres correspondant à la clé spécifiée
     * 
     * @param {*} key 
     */
    filters: (state, getters) => (key) => {
      let metadata = getters.filtersMetadata(key);
      let filters = state.filters.get(key);

      // Merger les données de la metadata et du cache
      return metadata.map((m) => {
        // Récupérer la valeur en cache si existante
        let cachedFilter = filters?.find((f) => f.property === m.property);

        // Si filtre partagé, récupérer la valeur du filtre partagé
        if (m.sharedKey) {
          cachedFilter = getters.sharedFilter(m.sharedKey) ?? cachedFilter;
        }

        // Récupérer l'opération correspondante dans le store
        let operation = getters.operation(
          m.operation ?? cachedFilter?.operation
        );

        // Renvoyer un bon gros merge des familles avec tout ça
        return {
          property: m.property,
          operation: operation?.code ?? null,
          ignoreCase: m.ignoreCase ?? cachedFilter?.ignoreCase ?? false,
          ignoreAccents: m.ignoreAccents ?? cachedFilter?.ignoreAccents ?? false,
          operands:
            cachedFilter?.operands ??
            new Array(operation?.minOperands ?? 0).fill(null),
        };
      });
    },
    /**
     * Renvoie un Array des filtres custom correspondant à la clé spécifiée
     * 
     * @param {*} key 
     */
    customFilters: (state, getters) => (key) => {
      let filters = getters.filters(key);
      let customProperties = getters.filtersMetadata(key).filter(m => m.custom === true).map(m => m.property);
      return filters.filter(f => customProperties.includes(f.property)) ?? [];
    },
    /**
     * Renvoie un Array des filtres standards correspondant à la clé spécifiée
     * 
     * @param {*} key 
     */
    standardFilters: (state, getters) => (key) => {
      let filters = getters.filters(key);
      let standardProperties = getters.filtersMetadata(key).filter(m => m.custom !== true).map(m => m.property);
      return filters.filter(f => standardProperties.includes(f.property)) ?? [];
    },
    /**
     * Renvoie le nombre de filtres actifs correspondant à la clé spécifiée
     * 
     * @param {*} key 
     */
    activeFilters: (state, getters) => (key) =>
      getters.filtersMetadata(key).filter(m => !!m.sharedKey)
        .reduce(
          // On compte les filtres partagés qui ont une valeur en cache
          (acc, next) => acc + (getters.sharedFilter(next.sharedKey) ? 1 : 0),
          // + le nombre de filtres standards enregistrés pour le tableau
          (state.filters.get(key)?.length ?? 0)
        ),
  },
  actions: {
    /**
     * Enregistre le pageable spécifié dans le store
     * 
     * La propriété partagée perPage est enregistrée à part
     */
    pageableChanged({ commit }, { key, pageable }) {
      // Supprimer les ordres de tri invalides
      if (Array.isArray(pageable.sortBy)) {
        pageable = JSON.parse(JSON.stringify(pageable));
        for (let i = 0; i < pageable.sortBy.length; i++) {
          if (!pageable.sortBy[i]) {
            pageable.sortBy.splice(i, 1);
            pageable.sortDesc.splice(i, 1);
          }
        }
      }

      commit("setPerPage", pageable.perPage);
      commit("setPageable", { key, pageable });
    },
    /**
     * Réinitialise les tris correspondant à la clé spécifiée
     * 
     * @param {*} key 
     */
    sortsReset({ getters, commit }, key) {
      // Deep copy pour ne pas partager les arrays par référence
      let initial = JSON.parse(JSON.stringify(defaultPageables.get(key)));
      commit("setPageable", {
        key,
        pageable: {
          ...getters.pageable(key),
          sortBy: initial.sortBy,
          sortDesc: initial.sortDesc,
        }
      });
    },
    /**
     * Réinitialise les filtres correspondant à la clé spécifiée
     * 
     * @param {*} key 
     */
    filtersReset({ getters, commit }, key) {
      // Réinitialiser les filtres partagés
      getters.filtersMetadata(key).filter(m => !!m.sharedKey).forEach(m => {
        commit("setSharedFilter", { key: m.sharedKey, shared: null });
      });

      // Réinitialiser les filtres spécifiques à l'écran
      commit("setFilter", { key, filters: [] });
    },
    /**
     * Enregistre les filtres spécifiés dans le store
     * 
     * Les filtres incorrects sont éliminés avant sauvegarde
     * Les filtres partagés sont enregistrés à part
     */
    filtersChanged({ getters, dispatch, commit }, { key, filters }) {
      // Eliminer les filtres incorrects
      filters = filters?.filter((f) => !!f.operation)
        .filter((f) => f.operands.reduce(
          (acc, next) => acc && next != null && next !== "",
          true
        ));

      // Si aucun filtre correct, vider le cache
      if ((filters?.length ?? 0) <= 0) {
        dispatch("filtersReset", key);
        return;
      }

      let metadata = getters.filtersMetadata(key);

      // Enregistrer les filtres partagés à part
      metadata.filter(m => !!m.sharedKey).forEach(m => {
        let shared = filters.find(f => f.property === m.property);
        commit("setSharedFilter", { key: m.sharedKey, shared });
      });

      // Enregistrer les filtres spécifiques à l'écran
      filters = metadata.filter(m => !m.sharedKey)
        .map(m => filters.find(f => f.property === m.property))
        .filter(f => f != null);
      commit("setFilter", { key, filters });
    },
  },
  mutations: {
    // /!\ Vue 2 ne gère pas la réactivité sur les objets de type Map, Set, etc.
    // Pour compenser, on peut utiliser un double assignement de la variable du state
    // Cf. https://stackoverflow.com/questions/37130105/does-vue-support-reactivity-on-map-and-set-data-types
    setPerPage(state, perPage) {
      state.perPage = perPage;
      localStorage.setItem("tabv2/perPage", JSON.stringify(state.perPage));
    },
    setPageable(state, { key, pageable }) {
      let temp = state.pageables;
      state.pageables = null;
      state.pageables = temp.set(key, pageable);
      localStorage.setItem("tabv2/pageables", JSON.stringify(Object.fromEntries(state.pageables.entries())));
    },
    setSharedFilter(state, { key, shared }) {
      let temp = state.sharedFilters;
      state.sharedFilters = null;
      state.sharedFilters = temp.set(key, shared);
      localStorage.setItem("tabv2/sharedFilters", JSON.stringify(Object.fromEntries(state.sharedFilters.entries())));
    },
    setFilter(state, { key, filters }) {
      let temp = state.filters;
      state.filters = null;
      state.filters = temp.set(key, filters);
      localStorage.setItem("tabv2/filters", JSON.stringify(Object.fromEntries(state.filters.entries())));
    },
  },
};
