// Imports composants standards Vue
import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";

// Imports autres composants applicatifs
import setupApiPwfb from "./api-pwfb";
import setupApiStrapi from "./api-strapi";
import "./validation";
import "./register-service-worker";

// Import CSS et icones sur mesure
import "./assets/fermesbio.scss";
import "./icones";

// Integration de Bootstrap
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

// Initialisation de l'application
// FIXME Rustine dégueulasse pour empêcher le cache sur le fichier de config
fetch(`/config.json?t=${Date.now()}`)
  .then((response) => response.json())
  .then((config) => {
    store.state.environment = config.ENVIRONMENT;
    setupApiPwfb(config, store, router);
    setupApiStrapi(config, store);
  })
  .then(() => {
    new Vue({
      store,
      router,
      render: h => h(App)
    }).$mount("#app");
  });

// FIXME Tester le demarrage hors reseau
// Voir pour cacher la config dans le store
// Et la reutiliser en cas d'erreur sur le fetch ?
