import axios from "axios";

// CLIENT HTTP POUR L'API FERMES BIO

export let apiPwfb;
export default (config, store, router) => {
  // Creation de l'instance Axios
  apiPwfb = axios.create({
    baseURL: config.PWFB_API_BASE_URL,
    headers: {
      "Content-Type": "application/json",
    },
  });

  // Ajout automatique du token approprie a toute requete sortante
  apiPwfb.interceptors.request.use(
    (config) => {
      if (store.state.auth.accessToken) {
        config.headers[
          "Authorization"
        ] = `Bearer ${store.state.auth.accessToken}`;
      } else {
        config.headers[
          "Authorization"
        ] = `Bearer ${store.state.auth.refreshToken}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Rafraichissement automatique de l'access token si besoin
  apiPwfb.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
      if (
        !originalConfig.url.startsWith("/auth") &&
        err.response &&
        err.response.status === 403 &&
        !originalConfig._retry
      ) {
        originalConfig._retry = true;
        try {
          await store.dispatch("auth/refresh");
          return apiPwfb(originalConfig);
        } catch (error) {
          router.push("/login");
          return Promise.reject(error);
        }
      }
      return Promise.reject(err);
    }
  );
};

// CLASSES ISSUES DE L'API FERMES BIO

export class Tiers {
  constructor(payload) {
    this.codeTiers = payload?.codeTiers;
    this.codeSociete = payload?.codeSociete;
    this.codeTypeTiers = payload?.codeTypeTiers;
    this.dateAdhesion = payload?.dateAdhesion;
    this.dateDebutBeef = payload?.dateDebutBeef;
    this.dateFinBeef = payload?.dateFinBeef;
    this.raisonSociale = payload?.raisonSociale;
    this.adresse1 = payload?.adresse1;
    this.adresse2 = payload?.adresse2;
    this.commune = payload?.commune;
    this.codePostal = payload?.codePostal;
    this.email = payload?.email;
    this.telephoneFixe = payload?.telephoneFixe;
    this.telephonePortable = payload?.telephonePortable;
    this.telephoneFax = payload?.telephoneFax;
    this.numeroSiret = payload?.numeroSiret;
    this.emailIntervenants = payload?.emailIntervenants ?? [];
    this.actif = payload?.actif ?? false;
  }
  getAdresseComplete() {
    return [
      [this.adresse1, this.adresse2].filter(p => !!p).join(" "),
      [this.codePostal, this.commune].filter(p => !!p).join(" "),
    ].filter(p => !!p).join(", ");
  }
}

export class Adresse {
  constructor(payload) {
    this.idAdresse = payload?.idAdresse;
    this.codeTypeAdresse = payload?.codeTypeAdresse;
    this.nomUsage = payload?.nomUsage;
    this.adresse1 = payload?.adresse1;
    this.adresse2 = payload?.adresse2;
    this.commune = payload?.commune;
  }
  getAdresseComplete() {
    return [
      [this.adresse1, this.adresse2].filter(p => !!p).join(" "),
      [this.commune?.codePostal, this.commune?.libelle].filter(p => !!p).join(" "),
    ].filter(p => !!p).join(", ");
  }
}
