import UtilsService, { SortDirection } from "../services/utils.service";
import StrapiService from "../services/strapi.service";

export const strapi = {
  namespaced: true,
  state: {
    pages: [],
    collections: [
      {
        code: "actualites", slug: "actualites", component: "StrapiActualiteCard", layout: "grid", cols: "auto",
        sort: UtilsService.sortBy(
          UtilsService.sortByNumberProperty("attributes.ordre", SortDirection.ASC),
          UtilsService.sortByStringProperty("attributes.publishedAt", SortDirection.DESC),
        ),
      },
      {
        code: "catalogues", slug: "catalogues", component: "StrapiCatalogueComponent", layout: "grid", cols: 12,
        publicationState: "preview",
        sort: UtilsService.sortBy(
          UtilsService.sortByNumberProperty("attributes.campagne", SortDirection.DESC),
          UtilsService.sortByNumberProperty("attributes.ordre", SortDirection.ASC),
        ),
      },
      {
        code: "contacts", slug: "equipes", component: "StrapiEquipeComponent", layout: "grid", cols: 12,
        sort: UtilsService.sortByNumberProperty("attributes.ordre"),
      },
      { code: "evenements", slug: "evenements", component: "StrapiAgendaComponent", layout: "unique", },
      {
        code: "questions", slug: "categories-questions", component: "StrapiCategorieQuestionComponent", layout: "grid", cols: 12,
        sort: UtilsService.sortByNumberProperty("attributes.ordre"),
      },
      {
        code: "reglements-campagne", slug: "reglements-campagne", component: "StrapiReglementCampagneComponent", layout: "grid", cols: 12,
        sort: UtilsService.sortBy(
          UtilsService.sortByNumberProperty("attributes.campagne", SortDirection.DESC),
          UtilsService.sortByNumberProperty("attributes.ordre", SortDirection.ASC),
        ),
      },
      {
        code: "assemblees-generales", slug: "assemblees-generales", component: "StrapiAssembleeGeneraleComponent", layout: "grid", cols: 12,
        sort: UtilsService.sortByNumberProperty("attributes.campagne", SortDirection.DESC),
      },
    ],
    composants: [
      { code: "blocs.ligne", component: "StrapiLigneComponent", },
      { code: "blocs.colonne", component: "StrapiColonneComponent", },
      { code: "blocs.carrousel", component: "StrapiCarrouselComponent", },
      { code: "blocs.pieces-jointes", component: "StrapiFichiersComponent", },
      { code: "tableau.actualites", component: "ActualitesWidget", defaultIcon: "ActualitesWidgetIcon", },
      { code: "tableau.compta", component: "ComptaWidget", defaultIcon: "ComptaWidgetIcon", },
      { code: "tableau.contacts", component: "ContactsWidget", defaultIcon: "ContactsWidgetIcon", },
      { code: "tableau.declarations", component: "DeclarationsWidget", defaultIcon: "ComptaWidgetIcon", },
      { code: "tableau.documents", component: "DocumentsWidget", defaultIcon: "DocumentsWidgetIcon", },
      { code: "tableau.indicateurs", component: "IndicateursWidget", defaultIcon: "IndicateursWidgetIcon", },
      { code: "tableau.libre", component: "LibreWidget", defaultIcon: "LibreWidgetIcon", },
    ],
  },
  getters: {
    // Accesseurs standards
    page: (state) => (id) =>
      state.pages.find((p) => p.id === id),
    collection: (state) => (code) =>
      state.collections.find((c) => c.code === code),
    composant: (state) => (code) =>
      state.composants.find((c) => c.code === code),
    // Listes filtrées
    pages: (state) => (codeSociete) =>
      state.pages.filter((p) => p.attributes.societe?.data?.attributes.codetiers === codeSociete),
  },
  actions: {
    loadPages({ commit, rootGetters }) {
      return StrapiService.getPagesByRoles(rootGetters["auth/groupes"]).then(
        (response) => commit("setPages", response.data.data),
        () => commit("setPages", [])
      );
    },
    clear({ commit }) {
      commit("setPages", []);
    }
  },
  mutations: {
    setPages(state, pages) {
      state.pages = pages;
    },
  },
};
