<template>
  <svg class="font-icon" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.25 8.125C16.9128 8.12426 17.5483 7.86062 18.0169 7.39194C18.4856 6.92326 18.7493 6.28781 18.75 5.625V3.75H16.875C16.2351 3.75104 15.6202 3.99871 15.1583 4.4415C14.8345 3.85395 14.3591 3.36394 13.7817 3.02248C13.2043 2.68102 12.5458 2.5006 11.875 2.5H10V4.375C10.0011 5.36923 10.3965 6.32242 11.0996 7.02544C11.8026 7.72847 12.7558 8.12391 13.75 8.125H14.375V16.25H6.875V13.125H7.5C8.16281 13.1243 8.79826 12.8606 9.26694 12.3919C9.73562 11.9233 9.99926 11.2878 10 10.625V8.75H8.125C7.4851 8.75104 6.87022 8.99871 6.40825 9.4415C6.08449 8.85395 5.60914 8.36394 5.0317 8.02248C4.45426 7.68102 3.79584 7.5006 3.125 7.5H1.25V9.375C1.25109 10.3692 1.64653 11.3224 2.34956 12.0254C3.05258 12.7285 4.00577 13.1239 5 13.125H5.625V16.25H1.25V17.5H18.75V16.25H15.625V8.125H16.25ZM15.625 6.25C15.6253 5.91858 15.7571 5.60083 15.9915 5.36648C16.2258 5.13213 16.5436 5.00033 16.875 5H17.5V5.625C17.4997 5.95642 17.3679 6.27417 17.1335 6.50852C16.8992 6.74287 16.5814 6.87467 16.25 6.875H15.625V6.25ZM6.875 11.25C6.87533 10.9186 7.00713 10.6008 7.24148 10.3665C7.47583 10.1321 7.79358 10.0003 8.125 10H8.75V10.625C8.74967 10.9564 8.61787 11.2742 8.38352 11.5085C8.14917 11.7429 7.83142 11.8747 7.5 11.875H6.875V11.25ZM5.625 11.875H5C4.33719 11.8743 3.70173 11.6106 3.23306 11.1419C2.76438 10.6733 2.50074 10.0378 2.5 9.375V8.75H3.125C3.78781 8.75074 4.42327 9.01438 4.89194 9.48305C5.36062 9.95173 5.62426 10.5872 5.625 11.25V11.875ZM14.375 6.875H13.75C13.0872 6.87426 12.4517 6.61062 11.9831 6.14194C11.5144 5.67326 11.2507 5.03781 11.25 4.375V3.75H11.875C12.5378 3.75074 13.1733 4.01438 13.6419 4.48305C14.1106 4.95173 14.3743 5.58719 14.375 6.25V6.875Z"
    />
  </svg>
</template>

<script>
export default {
  name: "EmblavementsTabIcon",
};
</script>
