<template>
  <section class="d-inline-block">
    <svg
      ref="icon"
      class="font-icon"
      style="fill: none"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="11.5" stroke="#ACD1C6" />
      <path
        d="M5.25 8.25H6.711C8.14575 8.25 9.48975 8.94975 10.3125 10.125C10.7178 10.7041 11.2568 11.1768 11.8838 11.5032C12.5107 11.8297 13.2072 12.0001 13.914 12H18.75"
        stroke="#4D5856"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.25 15.75H6.7125C7.41891 15.75 8.11488 15.5796 8.74135 15.2532C9.36782 14.9267 9.90627 14.454 10.311 13.875C10.7157 13.296 11.2542 12.8233 11.8806 12.4969C12.5071 12.1704 13.2031 12 13.9095 12H18"
        stroke="#4D5856"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.5 14.25L18.75 12L16.5 9.75"
        stroke="#4D5856"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <b-tooltip :target="() => $refs['icon']" triggers="hover" variant="primary">
      <slot>M&eacute;lange</slot>
    </b-tooltip>
  </section>
</template>

<script>
export default {
  name: "MelangeIcon",
};
</script>
